import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AspLoginService } from '../asp-login/asp-login.service';
import { AspBrandsService } from './asp-brands.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-asp-brands',
  templateUrl: './asp-brands.component.html',
  styleUrls: ['./asp-brands.component.scss']
})
export class AspBrandsComponent implements OnInit {
  loading: boolean = false;
  spareSelectedGarageName: any;
  brands: any = [];
  searchText:string = '';
  filteredBrands: any = [];
  numericArray: any = [0,1,2,3,4,5,6,7,8,9];
  uppercaseArray: any = [];
  isActiveAll: boolean = true;
  isActiveNum: boolean = false;
  // isActiveAlpha: boolean = false;
  activeLetter: string | null = null;
  sidebarVisible: boolean = false;

  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  isMobile: boolean;

  constructor(
    private aspLoginService: AspLoginService,
    private aspBrandsService: AspBrandsService,
    private toastr: ToastrService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  
    private breakpointObserver: BreakpointObserver,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {    
    this.loading = true;
    this.aspLoginService.checkLogin();
   
    this.generateAlpha();
    this.getAllBrands();
  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  

  
  generateAlpha(): any {
    this.uppercaseArray = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode('A'.charCodeAt(0) + i)
    );
  }


  searchFilterType = () =>{    
      this.filteredBrands = this.brands.filter(brand =>
        brand.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
  }

  filterBrandsByChar(filterVal) {
    if(filterVal=='all'){
      this.isActiveAll = !this.isActiveAll;
      this.isActiveNum = false;
      this.activeLetter = null;

      this.filteredBrands = this.brands;      
    }else if(filterVal=='numbers'){
      this.isActiveNum = !this.isActiveNum;
      this.isActiveAll = false;
      this.activeLetter = null;

      this.filteredBrands = this.brands.filter(brand =>
        !isNaN(brand.name) &&         
        brand.name.toLowerCase().match(/[0-9]/) 
      );
    }else{
      this.isActiveNum = false;
      this.isActiveAll = false;
      this.activeLetter = filterVal;
      
      this.filteredBrands = this.brands.filter(brand =>
        brand.name.toLowerCase().match(/[a-zA-Z]/) && 
        brand.name.toLowerCase().startsWith(filterVal.toLowerCase()) 
      );
    }

    // console.log(this.filteredBrands);
  }

  getAllBrands(): void {
    const deviceId = '';  // Replace with actual deviceId
    const source = 'Web';  // Replace with actual source
    const versionCode = 67;  // Replace with actual versionCode
  
    this.aspBrandsService.allBrands(deviceId, source, versionCode).subscribe(
      res => {
        if (res && res['code'] === 200) {
          this.loading = false;
          this.brands = res['data'] || [];
          this.filteredBrands = this.brands.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.toastr.error(res['message'], 'Error');
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        this.toastr.error('An error occurred while fetching brands', 'Error');
        console.error('Error fetching brands:', error);
      }
    );
  }
  

  brandSparesList = (name, type, imageUrl='',key='') => {
    localStorage.setItem('selectedSparesList', JSON.stringify({"name": name, "type": type, "imageUrl": imageUrl,"key":key}));
    localStorage.setItem('isDynamicPopup',"true")
    this.router.navigate(['spare-parts-list']);
  }

}
