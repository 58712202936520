import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspLoadingService {

  constructor(
    private http: HttpClient
  ) { }

  getLoadingMsg(){
    let paramData ={
      source: "web" 
    }
    // console.log(paramData);
    console.log('-----get loading msg-----------');  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/app/getAppData',paramData);
  }
}
