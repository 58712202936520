import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AspMyReportsComponent } from './asp-my-reports.component';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
      path: 'my-reports',
      component: AspMyReportsComponent,
  },
];

@NgModule({ 
  declarations: [AspMyReportsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
  ],
  exports: [AspMyReportsComponent,RouterModule],
})
export class AspMyReportsModule { }
