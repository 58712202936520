import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchSuggestionService {

  constructor(
    private http: HttpClient,
  ) { }

  getSearchSuggestions(rowKey, searchWord, universal, variantKey){  
    let paramData1 = {      
      rowKey: rowKey,
      searchWord: searchWord,
      universal: universal,
      variantKey: variantKey
      };
      console.log("paramData1",paramData1)
  
      console.log('-----Get Search Suggestions -----------');   
  
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/search/suggestions', paramData1);
    }
}
