<div [ngClass]="isMobile ? 'dashboardMobile' : 'dashboard'">
    <header class="dashboard-header">
        <h1>Request dashboard</h1>
        
        <!-- <div class="header-actions">
            <button class="settings-btn">⚙️ Settings</button>
            <button class="export-btn">⬆️ Export</button>
        </div> -->
        
    </header>
    <p>Monitor metrics, check reports and review performance</p>
    <section class="overview">
        <h2 style="font-weight: bold;margin-bottom: 1px;">Overview</h2>
        <div class="show-option">Show: {{ getYesterdayDate() }}</div>
    </section>

    <section class="cards" *ngIf="!isMobile">
        <div class="stats-grid">
            <div class="stat-card">
                <h3>Requests Received</h3>
                <h2>{{datatoday?.requestReceived}}</h2>
                <p>{{data?.requestReceived}} requests received this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Sent</h3>
                <h2>{{datatoday?.quoteSent}}</h2>
                <p>{{data?.quoteSent}} quote sent this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Send Due</h3>
                <h2>{{datatoday?.quoteSendDue}}</h2>
                <p>{{data?.quoteSendDue}} quotes due this month</p>
            </div>
           
            
        </div>
    </section>
    <section class="cards" *ngIf="!isMobile">
        <div class="stats-grid1">
            <div class="stat-card">
                <h3>Quote Approved</h3>
                <h2>{{datatoday?.quoteApproved}}</h2>
                <p>{{data?.quoteApproved}} quote approved this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Approval Due</h3>
                <h2>{{datatoday?.quoteApprovalDue}}</h2>
                <p>{{data?.quoteApprovalDue}} quote approvals due this month</p>
            </div>
        </div>
    </section>

    <section class="cards" *ngIf="isMobile">
        <div class="stats-gridMobile">
            <div class="stat-card">
                <h3>Requests Received</h3>
                <h2>{{datatoday?.requestReceived}}</h2>
                <p>{{data?.requestReceived}} requests received this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Sent</h3>
                <h2>{{datatoday?.quoteSent}}</h2>
                <p>{{data?.quoteSent}} quote sent this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Send Due</h3>
                <h2>{{datatoday?.quoteSendDue}}</h2>
                <p>{{data?.quoteSendDue}} quotes due this month</p>
            </div>
            <div class="stat-card">
                <h3>Quote Approved</h3>
                <h2>{{datatoday?.quoteApproved}}</h2>
                <p>{{data?.quoteApproved}} quote approved this month</p>
            </div>
            
        </div>
    </section>
    <section class="cards" *ngIf="isMobile">
        <div class="stats-grid1Mobile">
            <div class="stat-card">
                <h3>Quote Approval Due</h3>
                <h2>{{datatoday?.quoteApprovalDue}}</h2>
                <p>{{data?.quoteApprovalDue}} quote approvals due this month</p>
            </div>
        </div>
    </section>
</div>