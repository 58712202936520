import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspSwitchGarageComponent } from './asp-switch-garage.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AspSwitchGarageComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FormsModule
  ],
  exports: [AspSwitchGarageComponent],
})
export class AspSwitchGarageModule { }
