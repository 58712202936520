import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspCustomerQuoteService {

  constructor(private http: HttpClient) { }
 
  getQuoteDetails(garageKey,quoteId,userKey){
    let param={
      garageKey:garageKey,
      quoteId: quoteId,
      userKey:userKey,
      source: "web" 
    }
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/detail`,param)
  }
  submitData(payload: any) {
    const paramData=payload;
    console.log("paramData",JSON.stringify(paramData))
    return this.http.post(`${GlobalVariable. BASE_API_URL_SPARES}/garage/customer/quote/create`, paramData);
}
requestDetails(garageKey, requestId, userKey){
  let paramData = {      
    garageKey: garageKey,
    requestId: requestId,
    userKey: userKey,
    source: "web" 
    
  };

  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/request/details', paramData);
}

submitDataUpdate(payload: any){
  const paramData=payload;
  
  console.log('-----submitDataUpdate-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/update`, paramData);

}
  
}
