import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-asp-shipping-charges-popup',
  templateUrl: './asp-shipping-charges-popup.component.html',
  styleUrls: ['./asp-shipping-charges-popup.component.scss']
})
export class AspShippingChargesPopupComponent implements OnInit {

  constructor(
    public dialog:MatDialogRef<AspShippingChargesPopupComponent>
  ) { }

  ngOnInit(): void {
  }

  closePopup(){
    this.dialog.close();
  }


}
