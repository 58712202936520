import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspMyBookingsComponent } from './asp-my-bookings.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClientModule } from '@angular/common/http';  // Make sure HttpClientModule is imported
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



const routes: Routes = [
  {
      path: 'my-bookings',
      component: AspMyBookingsComponent,
      
  },
];



@NgModule({
  declarations: [AspMyBookingsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    BrowserAnimationsModule
  ],
  exports: [AspMyBookingsComponent,RouterModule],
})
export class AspMyBookingsModule { }
