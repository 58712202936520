<div class="Item">
    <h2>My Item List(15)</h2>
    <div class="spares-card">
      <div class="logo">
        <img src="path/to/logo.png" alt="Logo" />
      </div>
      <div class="details">
        <p><strong>Manza, Diesel</strong></p>
        <p>1.3L CELEBRATION, [2010]</p>
        <p>KA01MF9749</p>
      </div>
    </div>
  
  
<div class="container">
    <!-- Sidebar -->
    <div class="sidebar">
      <mat-nav-list>
        <mat-list-item
            *ngFor="let category of categories"
            (click)="selectCategory(category)"
            [class.selected]="selectedCategory?.id === category.id"
            >
            <span matLine>{{ category.name }}</span>
            <span matLine style="font-size:11px; font-weight: 100;">{{ category.name }}</span>
            <span style="font-size: 11px;">10+ items</span>
            <i class="material" (click)="openEdit(category)">edit</i>
            <div class="overlay" [class.visible]="editSheetOpen" (click)="closeEdit()"></div>

            <div class="edit-item-container" *ngIf="editSheetOpen && editingCategory?.id === category.id">
                <div class="edit-item-content">
                <h3>Edit Item</h3>
                <button class="close-btn" (click)="closeEdit()">
                    <i class="material-icons">close</i>
                </button>
                <form [formGroup]="editItemForm" (ngSubmit)="saveChanges()">
                    <div class="form-group">
                    <label for="itemName">Item Name</label>
                    <input
                        type="text"
                        id="itemName"
                        formControlName="itemName"
                        placeholder="{{category.name}}"
                    />
                    </div>
                    <div class="form-actions">
                    <button type="button" (click)="removeFromList(category)">Remove from list</button>
                    <button type="submit">Save Changes</button>
                    </div>
                </form>
                </div>
            </div>
            </mat-list-item>

      </mat-nav-list>
    </div>
  
    <!-- Content -->
    <div class="content">
      <h2>{{ selectedCategory?.name || 'Select a Category' }}</h2>
      <div class="product-grid">
        <mat-card *ngFor="let product of selectedCategory?.products" class="product-card">
            <!-- <a [routerLink]="['/product-details/key/']"> -->
            <div class="product-badge">
                <span class="discount1">7% OFF</span>
                <span class="bought">Previously Bought</span>
              </div>
          <img mat-card-image src="../../../../assets/images/logos/logo.png" alt="{{ product.name }}" />
          <mat-card-content style="color: black;">
            <h3>{{ product.name }}</h3>
            <div class="price">
                <span class="current-price">₹{{ product.price }}</span>
                <span class="original-price">₹{{ product.originalPrice }}</span>
                <span class="discount">{{ product.discount }}% OFF</span>
            </div>
            <div class="product-rating">
                <span class="star"><i class="material-icons star-icon">star</i></span> 5 Ratings
              </div>
              <div class="product-details">
                <div class="detail-item">
                  <img src="../../../../assets/images/spares-list-images/unnamed (1).png" style="width: 18px;height: 18px;" alt="PN">
                  18855-10060</div>
                <div class="detail-item">
                  <img src="../../../../assets/images/spares-list-images/unnamed.png" style="width: 18px;
                  height: 18px;" alt="B">
                   Hyundai/Kia</div>
                <div class="detail-item">
                  <img src="../../../../assets/images/spares-list-images/unnamed (2).png"style="width: 18px;
                  height: 18px; " alt="T">
                  45 Mins</div>
              </div>
            <!-- <div class="actions">
              <button mat-icon-button (click)="decreaseQuantity(product)">-</button>
              <span>{{ product.quantity }}</span>
              <button mat-icon-button (click)="increaseQuantity(product)">+</button>
            </div> -->
          </mat-card-content>
        <!-- </a> -->
        
        </mat-card>
      </div>
    </div>
  </div>
</div>

