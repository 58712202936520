import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-asp-order-summary',
  templateUrl: './asp-order-summary.component.html',
  styleUrls: ['./asp-order-summary.component.scss']
})
export class AspOrderSummaryComponent implements OnInit {
  loading:boolean = false;
  cartSubTotal = 1000;
  sparesDiscount = 100;
  shippingCharges = 10;
  cartGrandTotal = 910;
  shippingChargesApplicable:boolean = true;
  spareSelectedGarageName: any;

  constructor(
    private resolver: ComponentFactoryResolver,
  ) { }
  
  ngOnInit(): void {    
     }
  

  downloadInvoice(){    
    // let downloadFile = 'https://cube.getpitstop.com/assets/img/volkswagen.png';
    let downloadFile = 'assets/icons/order-status.png';
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadFile);
    link.setAttribute('download', `invoice`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    // const blob = new Blob([downloadFile]);
    // const url= window.URL.createObjectURL(blob);
    // window.open(url);
  }
}
