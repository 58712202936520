
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AspProductDetailsService } from '../asp-product-details/asp-product-details.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-asp-prduct-compatibility-popup',
  templateUrl: './asp-prduct-compatibility-popup.component.html',
  styleUrls: ['./asp-prduct-compatibility-popup.component.scss']
})
export class AspPrductCompatibilityPopupComponent implements OnInit, AfterViewInit {
  selectedTab: string = 'diesel';
  vehicle: any[] = [];
  variants: any = {};
  getSelectedVariants: any[] = [];
  yearList: number[] = [];
  getSelectedYears: any[] = [];
  VariantsList: string[] = [];
  fuelType: string[] = [];
  getSelectedTitles: string[] = [];
  getSelectedFuel: any;
  selectedCompatibleYear: number;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selectedModel: string = '';
  selectedYear: number;

  constructor(
    public dialog: MatDialogRef<AspPrductCompatibilityPopupComponent>,
    public aspProductDetailsService: AspProductDetailsService
  ) {
    dialog.disableClose = true;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getVariants({
      index: this.tabGroup.selectedIndex,
      tab: this.tabGroup._tabs.toArray()[this.tabGroup.selectedIndex]
    });
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  getProductCompatability(partNumber: string) {
    this.aspProductDetailsService.getProductCompatability(partNumber).subscribe(
      res => {
        if (res['code'] == 200) {
          this.vehicle = res['data'].vehicle;
          this.variants = res['data'].variants;

          let rowKey = this.vehicle[0]['models'][0]['rowKey'];
          this.getYear(rowKey);
        }
      }
    );
  }

  getYear(rowKey: string) {
    this.selectedModel = rowKey;
    this.getSelectedYears = [];
    this.yearList = [];
    this.fuelType = [];
    this.VariantsList = [];
    if (this.variants[rowKey]) {
      Object.values(this.variants[rowKey]).forEach((list: any) => {
        this.getSelectedYears.push(list);
        let yearFrom = list['yearFrom'];
        let yearTo = list['yearTo'];
        for (let i = yearFrom; i <= yearTo; i++) {
          if (this.yearList.indexOf(i) === -1) {
            this.yearList.push(i);
          }
        }
      });
      this.yearList.sort((a, b) => a - b);
      this.getFuelType(this.yearList[0]);
    }
  }

  getFuelType(year: number) {
    this.selectedYear = year;
    this.selectedCompatibleYear = year;
    this.getSelectedFuel = [];
    this.fuelType = [];
    Object.entries(this.variants).forEach(([key, variant]) => {
      Object.values(variant).forEach((list: any) => {
        this.getSelectedFuel.push(list);
        let yearFrom = list['yearFrom'];
        let yearTo = list['yearTo'];
        if (year >= yearFrom && year <= yearTo) {
          let fuelType = list['fuelType'];
          if (this.fuelType.indexOf(fuelType) == -1) {
            this.fuelType.push(fuelType);
          }
        }
      });
    });
    const event: MatTabChangeEvent = {
      index: this.tabGroup.selectedIndex,
      tab: this.tabGroup._tabs.toArray()[this.tabGroup.selectedIndex]
    };
    this.getVariants(event);
  }

  getVariants(event: MatTabChangeEvent) {
    if (!event.tab?.textLabel) return;

    this.getSelectedVariants = [];
    this.VariantsList = [];
    Object.entries(this.variants).forEach(([key, variant]) => {
      Object.values(variant).forEach((list: any) => {
        this.getSelectedVariants.push(list);
        let fuelType = list['fuelType'];
        let yearFrom = list['yearFrom'];
        let yearTo = list['yearTo'];
        if (event.tab.textLabel === fuelType && this.selectedCompatibleYear >= yearFrom && this.selectedCompatibleYear <= yearTo) {
          let title = list['title'];
          if (this.VariantsList.indexOf(title) == -1) {
            this.VariantsList.push(title);
          }
        }
      });
    });
  }

  closePopup() {
    this.dialog.close();
  }
}
