import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
// import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
import { MatRadioModule } from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AspSparesListComponent } from './asp-spares-list.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

// Filter
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
// import { MaterialModule } from '@angular/material/';
import { MatMenuModule } from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {FormsModule} from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web'; 

export function playerFactory() {
  return player;
}

const routes: Routes = [
  {
      path: 'spare-parts-list',
      component: AspSparesListComponent,
      
  },
];
@NgModule({
  declarations: [AspSparesListComponent],
  imports: [    
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatCardModule,
    FormsModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    CdkStepperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    // ApplicationPipesModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSlideToggleModule,

    // Filter
    MatExpansionModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    // MaterialModule
    MatMenuModule,
    ScrollingModule,
    MatPaginatorModule,
    LottieModule.forRoot({ player: playerFactory }) 
  ],  
  exports: [AspSparesListComponent],
})
export class AspSparesListModule { }
