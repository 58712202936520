import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import {AspModelService} from './asp-model.service';
import {AspLoginService} from '../asp-login/asp-login.service';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';

@Component({
  selector: 'app-asp-model',
  templateUrl: './asp-model.component.html',
  styleUrls: ['./asp-model.component.scss']
})
export class AspModelComponent implements OnInit, AfterViewInit {
  loading:boolean = false;
  selectedModel: any;
  searchText:string = '';
  modelList:any;
  openSearch:boolean = true;
  clearSearch:boolean = false;
  selectedData = '';
  spareSelectedGarageName: any;
  selectedMake: any;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  constructor(
    private router: Router,
    private aspModelService: AspModelService,
    private aspLoginService: AspLoginService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) { }
 
  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('selectedModel')){
      this.selectedData = localStorage.getItem('selectedModel');
    }

    this.getModelList();
    
    
  
  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  

  // Get Model List   
  getModelList(){
    let result = this.aspModelService.getModel();
    this.loading = false;
    let res = JSON.parse(result); 
    this.modelList = res.data[this.selectedMake];
  }  
  // End Get Model List

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  
  selectModel(model: any) {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newModel', model); 
    else
      localStorage.setItem('selectedModel', model); 

    this.router.navigate(['year']);

  }

  goToMake(){      
    this.router.navigate(['make']);
  }
    
}