<div class="page-layout blank fullwidth p-12 coupon-code" fusePerfectScrollbar>
    <h2 class="coupon-code-title">Coupon Code</h2>

    <!-- <mat-form-field appearance="outline">
        <mat-label>Coupon Code</mat-label>
        <input matInput [formControl]="coupon-code" required>     
    </mat-form-field> -->
    <label>Coupon Code</label>
    <input type="text" name="coupon-code" id="coupon-code" />

    <div>
        <button mat-raised-button class="apply-popup" (click)="closePopup()">Apply</button> 
    </div>
</div>