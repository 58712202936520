import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf(".json") === -1 && req.url.indexOf('docs.') === -1) {
            // if(localStorage.getItem('spareLoginToken') && localStorage.getItem('spareLoginToken')!=''){
            //     console.log(req.url);
            //     let authToken = `Bearer ${localStorage.getItem('spareLoginToken')}`;
            //     const authReq = req.clone({
            //         headers: new HttpHeaders({
            //             "x-api-key": environment.API_VERIFICATION_KEY,
            //             'Authorization': authToken,
            //             // 'phone': "9898989898"
            //         }).set('phone', '9898989898'),
            //     });
            //     return next.handle(authReq);
            // }else{
                console.log(req.url);
                const authReq = req.clone({
                    headers: new HttpHeaders({
                        "x-api-key": environment.API_VERIFICATION_KEY,
                    }),
                });
                return next.handle(authReq);
            // }
        } else {
            const authReq = req.clone();
            return next.handle(authReq);
        }
    }

}
