import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AspQuotationListService {

  constructor(
    private http: HttpClient,
  ) { }

  orderList(userKey, garageKey){
    let paramData = {      
      userKey: userKey,
      garageKey: garageKey,
      source: "web"
    }
  
    console.log('-----Get Order List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/all?page=0&size=1000`, paramData);
  
  }
}
