import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspMyJobCardsComponent } from './asp-my-job-cards.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClientModule } from '@angular/common/http';  // Make sure HttpClientModule is imported
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  {
    path: 'my-job-cards',
    component: AspMyJobCardsComponent,
  },
];

@NgModule({
  declarations: [AspMyJobCardsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
  ],
  exports: [AspMyJobCardsComponent, RouterModule],
})
export class AspMyJobCardsModule { }
