import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspPrductBottomsheetService {

  constructor(
    private http: HttpClient,
  ) { }
  
  productDetails(garageKey, partNumber){  
    let paramData = {      
        garageKey: garageKey,
        partNumber: partNumber,
        source: "web"
      };
  
      console.log('-----Get Product Details-----------');    
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/detailByPartNumber', paramData);
    }
}
