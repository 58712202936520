import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AspJoinAutoverseComponent } from './asp-join-autoverse.component';

const routes: Routes = [
  {
      path: 'join',
      component: AspJoinAutoverseComponent,
  },
];

@NgModule({
  declarations: [AspJoinAutoverseComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],  
  exports: [AspJoinAutoverseComponent],
})
export class AspJoinAutoverseModule { }
