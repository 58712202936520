<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>    
    <div *ngIf="this.data?.plannedSku==1">        
        <p>We have <strong>{{this.data?.availableStock}} quantity</strong> available locally and <strong>{{this.data?.count - this.data?.availableStock}}</strong> more shipping from another warehouse.</p>
        <p>Delivery will take approximately <strong>180</strong> mins instead of <strong>45</strong> mins.</p>
        <p>Thank you for your patience and understanding!</p>
        <div>
            <button mat-raised-button class="submit-planned-inventory-popup" (click)="addItemToCart(this.data?.count)">Deliver {{this.data?.count}} in 180 Mins</button> 
            <button mat-raised-button class="close-popup" (click)="closePopup()">Deliver {{this.data?.availableStock}} in 45 Mins</button> 
        </div>
    </div>

    <div *ngIf="this.data?.plannedSku!=1">        
        <p>We have <strong>{{this.data?.availableStock}} quantity</strong> available locally and <strong>{{this.data?.count - this.data?.availableStock}}</strong> more shipping from another warehouse.</p>
        <p>Slight delay expected.</p>
        <p>Thank you for your patience and understanding!</p>
        <div>
            <button mat-raised-button class="submit-planned-inventory-popup" (click)="addItemToCart(this.data?.count)">Okay, I Will Wait</button> 
            <button mat-raised-button class="close-popup" (click)="closePopup()">Remove this item</button> 
        </div>
    </div>
    
</div>