

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspPaymentlistService {
  createPaymentLink: any;

  constructor(
    private http: HttpClient
  ) {}

//   
getUserDetails(
  contactName: string,
  amount: string,
  garageKey: string,
  mobileNumber: string,
  paymentFor: string,
  userId: string
): Observable<any> {
  const paramData = {
    garageKey:garageKey,
    amount:amount,
    contactName:contactName,
    mobileNumber:mobileNumber,
    paymentFor:paymentFor,
    userId:userId,
    source: "web"
  };

  console.log('-----Get User Details-----------');
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/payment/link/create`, paramData);

}
storeUserDetails
(
  garageKey:string,
  search:string,
  status:string,
  userId:string,
):Observable<any> {
  const paramData= {
    garageKey:garageKey,
    userId:userId,
    search:search,
    status:status,
    source: "web"
  };

console.log('------------Get Stored Data------------------');
return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/payment/link/get/all?page=0&rows=1000`,paramData);
}
cancelUserDetails
(garageKey:string,
  paymentId:string,
  userId:string,  
):Observable<any>{
  const paramData={
    garageKey: garageKey,
    paymentId: paymentId,
    userId: userId,
    source: "web"
  };
  console.log('----------------Cancel Data---------------------');
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/payment/link/cancel`,paramData)
}


}