<div class="order-success-sec">
    <img src="{{successIcon}}" >
    <h3>Quote-Approved!</h3>
    <p class="order-label" ><span class="order-id" >{{data.id}}</span></p>
    <!-- <p class="date"><span>{{data.approvedAt  | date:'dd MMM yyyy'}}</span></p> -->
    <p class="date"><span>{{ approvedAt }}</span></p>

    <p class="thank you">Thank you for aproving the quote</p>
    <button class="goto-order" (click)="goToQuoteApproved()">View Approved Quote</button>
    <!-- <button class="goto-order" (click)="goToQuoteApproved()" *ngIf="activeTab === 'quote-received' && (quotationreceived.length > 0 || quotationapproved.length > 0)">View Approved Quote</button> -->
    <!-- <button type="button" class="QuoteBtn"  (click)="openSidebarQuote()" *ngIf="activeTab === 'quote-received' && (quotationreceived.length > 0 || quotationapproved.length > 0)" >Create Customer Quote</button> -->

</div>