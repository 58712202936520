import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-asp-my-item-list',
  templateUrl: './asp-my-item-list.component.html',
  styleUrls: ['./asp-my-item-list.component.scss']
})
export class AspMyItemListComponent implements OnInit {

  categories = [
    {
      id: 1,
      name: 'Oil Filter',
      image: 'assets/images/vegetables.jpg',
      products: [
        {
          name: 'Oil Filter (B)',
          image: 'assets/images/onion.jpg',
          weight: '1 kg',
          discount: 24,
          originalPrice: 108,
          price: 82,
          weights: ['500 g', '1 kg', '2 kg'],
          quantity: 1,
        },
        {
          name: 'Oil Filter (s)',
          image: 'assets/images/potato.jpg',
          weight: '1 kg',
          discount: 26,
          originalPrice: 75,
          price: 55,
          weights: ['500 g', '1 kg', '2 kg'],
          quantity: 1,
        },
      ],
    },
    {
      id: 2,
      name: 'Air Filter',
      image: 'assets/images/fruits.jpg',
      products: [
        {
          name: 'Air Filter (B)',
          image: 'assets/images/apple.jpg',
          weight: '1 kg',
          discount: 15,
          originalPrice: 150,
          price: 128,
          weights: ['500 g', '1 kg', '2 kg'],
          quantity: 1,
        },
        {
          name: 'Air Filter (s)',
          image: 'assets/images/banana.jpg',
          weight: '1 dozen',
          discount: 20,
          originalPrice: 60,
          price: 48,
          weights: ['6 pcs', '1 dozen'],
          quantity: 1,
        },
      ],
    },
  ];

  selectedCategory: any;
  editSheetOpen: boolean;
  editingCategory:any= null;
  editItemForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.editItemForm = this.fb.group({
      itemName: [''],
    });
  }

  ngOnInit(): void {
    this.selectCategory(this.categories[0]); // Default selection
  }

  selectCategory(category: any): void {
    this.selectedCategory = category;
  }

  increaseQuantity(product: any): void {
    product.quantity++;
  }

  decreaseQuantity(product: any): void {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }

  openEdit(category: any) {
    this.editSheetOpen = true;
    this.editingCategory = category;

    // Set the current category name into the form control
    this.editItemForm.patchValue({
      itemName: category.name,
    });
  }

  closeEdit() {
    this.editSheetOpen = false;
    this.editingCategory = null;
  }

  saveChanges() {
    if (this.editItemForm.valid) {
      const updatedName = this.editItemForm.value.itemName;
      if (this.editingCategory) {
        this.editingCategory.name = updatedName;
      }
      this.closeEdit();
    }
  }

  removeFromList(category: any) {
    this.categories = this.categories.filter((cat) => cat.id !== category.id);
    this.closeEdit();
  }
}
