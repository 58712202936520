<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>    
    
    
    <!-- <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
    
    <mat-card class="mat-elevation-z8 mt-8 p-8 brands"> 
        <mat-card-header>
            <mat-card-title>All Brands</mat-card-title>  
        </mat-card-header>
            
        <mat-card-content>
            <div fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <form class="searchBrands-form">        
                        <input type="text" class="search-bar" [ngClass]="{'search-bar_Mobile': isMobile}" placeholder="Search Brands..." [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()">
                    </form>

                    <div class="filterButtons" >
                        <button class="all" (click)="filterBrandsByChar('all')" [class.active]="isActiveAll">All</button>
                        <button class="numbers" (click)="filterBrandsByChar('numbers')" [class.active]="isActiveNum">0-9</button>
                        <button class="alphabets" *ngFor="let alpha of uppercaseArray" (click)="filterBrandsByChar(alpha)" [class.active]="alpha === activeLetter">{{alpha}}</button>
                    
                        <p *ngIf="isActiveAll">All</p>
                        <p *ngIf="isActiveNum">0-9</p>
                        <p *ngIf="activeLetter">{{activeLetter}}</p>
                    </div>

                    <div *ngFor="let brand of filteredBrands" fxFlex="23.7%" [ngClass]="{'brand_Mobile': isMobile}">                        
                        <!-- <mat-card> -->
                            <!-- <img src="{{brand.imageUrl}}"> -->
                            <div class="brand_name" (click)="brandSparesList(brand.name, 'brandSpares', brand.imageUrl, brand.key)">{{brand.name}}</div>
                        <!-- </mat-card> -->
                    </div>

                </div>
            </div>
        </mat-card-content>
    </mat-card>

</div>
<div *ngIf="loading">
    <div #dynamicContainers></div>
    </div>