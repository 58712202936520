<div class="my-request">
    <div class="container">
      <div class="main-content">
        <!-- Search Bar Section -->
        <div class="SearchBarPlaceholder">
          <div class="search">
            <div class="input-container">
              <span *ngIf="searchText" class="close-button" (click)="clearSearch()">✖</span>
              <div class="scroll-text-container" *ngIf="!searchText">
                <div class="SearchBarIcon">
                  <i class="material-icons-search" >search</i>
                </div>
                <div class="scroll-text">
                  <div id="animationText-1" class="SearchBarAnimationText">Search By "Booking ID"</div>
                  <div id="animationText-2" class="SearchBarAnimationText">Search By "Name"</div>
                  <div id="animationText-3" class="SearchBarAnimationText">Search By "Mobile Number"</div>
                </div>
              </div>
              <input type="text" class="search-input" [(ngModel)]="searchText" (keyup)="onEnterPress($event)" (input)="filterRequest()">

            </div>
            <button #myButton class="create-booking" (click)="onCreateJobCardClick()">Create Job Card</button>
          </div>
        </div>
        
  
        <!-- Sidebar Section -->
        <div class="overlay" [class.visible]="isVisible" (click)="toggleSidebar()"></div>
        <div class="sidebar" *ngIf="isSidebarOpen">
        <div class="sidebar-content">
            <div class="sidebarheading">
            <h2 class="crtbkng">Create Job Card</h2>
            <button class="close-sidebar" (click)="toggleSidebar()">×</button>
            </div>
            <form>
              <div class="form-group"></div>
                <label for="name" style="font-weight: bolder;">Enter Customer Name <span class="required">*</span></label>
                <div class="input-group">
                    <span class="small-img">
                        <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt=""> NM
                    </span>
                    <input type="text" id="name" placeholder="enter customer name" >
                </div>
                <small>Enter Your Name</small>




                <div class="form-group"></div>
                <label for="name" style="font-weight: bolder;">Enter PhoneNumber <span class="required">*</span></label>
                <div class="input-group">
                    <span class="small-img">
                        <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt=""> +91
                    </span>
                    <input type="text" id="name" placeholder="enter phoneNumber" >
                </div>
                <small>Enter Your PhoneNumber</small>

   
            <div class="form-group">
                <label for="odometer" style="font-weight: bolder;">Enter Odometer Reading <span class="required">*</span></label>
                <div class="input-group">
                    <span class="small-img">
                        <img src="../../../../assets/images/my-job-card-images/carkm.png" class="input-icon" alt="Car KM"> KM
                    </span>
                    <input type="text" id="odometer" placeholder="10000" >
                </div>
                <small>Your Odometer Reading</small>
                <!-- <div *ngIf="(odometer.invalid && odometer.touched) || (odometer.invalid && formSubmitted)" style="color: red;">
                Enter Odometer Reading
                </div> -->
                
            </div>
            <div class="form-group">
                <label for="odometer" style="font-weight: bolder;">We recommend to enter car number <span class="required">*</span></label>
                <div class="input-group">
                    <span class="small-img">
                        <img src="../../../../assets/images/my-job-card-images/circle.png" class="input-icon" alt="Car KM"> IND
                    </span>                
                    <input type="text" id="car-number" placeholder="KA 04 JA54**" >
                </div>
                <small>Your Car Number</small>
                <!-- <div *ngIf="(carNumberInput.invalid && carNumberInput.touched) || (carNumberInput.invalid && formSubmitted)" style="color: red;">
                Please enter car number
                </div> -->
            </div>
            <ul class="info-list">
                <li>
                    <mat-icon class="circle">check_circle</mat-icon>
                    <span class="get">Get accurate details</span>
                </li>
                <li>
                    <mat-icon class="circle">check_circle</mat-icon>
                    <span class="get">Get 2X faster results</span>
                </li>
            </ul>
            <a class="view-btn" style="float: right; border: none; background-color: white; color: #4caf50;">
              <span class="job-card" style="float: left;">Create Job Card</span>
            </a>
            <p class="manual-selection">Or select car manually</p>
            </form>
            <div #dynamicContainer></div>
        </div>
        </div>
        <div class="popup" *ngIf="isPopupOpen">
          <div class="popup-content order-success-sec">
            <img src="{{successIcon}}" alt="">
            <button class="close-popup" (click)="closePopup()">×</button>
            <h3>Job Card Created</h3>
            <p class="order-label"><span class="order-id">Job Card ID</span></p>
            <p class="date"><span>date and time</span></p>
            <p class="thank-you">Let us deliver an awesome experience!</p>
            <div class="image-share">
              <button class="goto-order" (click)="shareOnWhatsApp()">
                <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="Share"> SHARE JOB CARD
              </button>
            </div>
          </div>
        </div>
        
  
        <!-- Other Content Section -->
        <div class="header" *ngFor="let jobCard of filteredJobCards">
          <div class="header-row" >
            <div class="request-info">
              <div class="header-top-row">
                <div class="order-icon" style="width: 4%;">
                  <div class="car-img">
                    <img [src]="getMakeImageUrl(jobCard.make)" alt="Car Make Image" class="model-icon">
                  </div>
                </div>
                <div class="book-id">Job Card ID # {{ jobCard.id }}</div>
                <div class="right-container">
                  <div class="right-container-icon" (click)="shareOnWhatsApp()">
                    <!-- <i class="share-icon">share</i> -->
                  </div>
                </div>
              </div>
              <div class="name-mbl-num">
                <div class="name">{{ jobCard.make }}, {{ jobCard.fuelType }}</div>
                <div class="mobile-number">{{ jobCard.variant }}</div>
                <div class="mobile-number">{{ jobCard.registrationNumber }}</div>
              </div>
            </div>
          </div>
          <div class="header-row2">
            <div class="placed-on-date">{{ jobCard.name }}</div>
            <a class="view-btn">
              <span class="phone-number">{{ jobCard.phoneNumber }}</span>
            </a>
          </div>
          <div class="header-row3">
            <div class="placed-on-date"> Placed On {{ jobCard.createdAt | date:'dd MMM yyyy, h:mm a' }}</div>
            <a class="view-btn" [routerLink]="['/my-job-cards-details', jobCard.id, jobCard.bookingId]">
              <span class="job-card">View Details</span>
              <i class="material-icons" style="color: #4caf50; float: left;">arrow_right</i> 
            </a>
          </div>
        </div>
      </div>
    </div>
   <!-- Confirmation Sidebar -->
<div class="overlay" [class.visible]="isVisible" (click)="toggleConfirmationSidebar()"></div>
<div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen">
  <div class="confirmation-content">
    <div class="header-container">
      <mat-icon class="back-arrow" (click)="toggleConfirmationSidebar()">arrow_back</mat-icon>
      <mat-icon style="color: #144579;">directions_car</mat-icon>
      <img src="../../../../assets/images/my-job-card-images/img_share.png" alt="">
    </div>
    <h3>Confirm your car details</h3>
    <!-- <div *ngFor="let jobCard of request?.jobCards"> -->
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="">
        <div class="label">Make:</div>
        <div class="value">{{ jobCard.make }}</div>
      </div>
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
        <div class="label">Model:</div>
        <div class="value">{{ jobCard.model }}</div>
      </div>
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
        <div class="label">Variant:</div>
        <div class="value">0.8L LX (TYPE 2) (47 h.p.){{ jobCard.variant }}</div>
      </div>
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
        <div class="label">MFG Year:</div>
        <div class="value">2010</div>
      </div>
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
        <div class="label">Fuel:</div>
        <div class="value">{{ jobCard.fuelType }}</div>
      </div>
      <div class="details-item">
        <img src="../../../../assets/images/my-job-card-images/ic_check.png" alt="">
        <div class="label">VIN:</div>
        <div class="value">MA3EAA61S01699103</div>
      </div>
    </div>
    <button class="confirm-btn" (click)="confirmCarDetails()">Confirm</button>
    <button class="change-car-btn" (click)="toggleConfirmationSidebar()">Change Car</button>
  </div>
</div>

<ng-template #dynamicContainer></ng-template>




