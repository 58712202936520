import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Add ReactiveFormsModule
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; // Import MatInputModule
import { CatAllComponent } from './cat-all.component';
import { ConfirmationPopupComponent } from '../cat-all/cat-all-popup/cat-all-popup.component';

const routes: Routes = [
  {
    path: 'catalogue',
    component: CatAllComponent,
  },
];

@NgModule({
  declarations: [
    CatAllComponent,
    ConfirmationPopupComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule, // Add ReactiveFormsModule
    MatFormFieldModule,
    MatInputModule // Add MatInputModule
  ],
  entryComponents: [
    ConfirmationPopupComponent
  ],
  exports: [CatAllComponent],
})
export class CatAllModule { }
