import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AspLoginService } from './asp-login.service';
import { MatDialog } from '@angular/material/dialog';
import { AspSwitchGarageComponent } from '../asp-switch-garage/asp-switch-garage.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-asp-login',
  templateUrl: './asp-login.component.html',
  styleUrls: ['./asp-login.component.scss']
})
export class AspLoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  
  @ViewChild('mobileInput', { static: false }) mobileInput: ElementRef;

  loginForm: FormGroup;  
  requestOTPForm: FormGroup;  
  loading = false;
  hide: boolean = true;
  enableOTP = false;
  isMobileDisabled: boolean = true;
  countdown: number = 60;
  timerSubscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isMobile: boolean;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private aspLoginService: AspLoginService, 
    private toastr: ToastrService,    
    public dialog: MatDialog,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
    };
    
    this.aspLoginService.thisCheckLogin();

    this.requestOTPForm = this._formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(10)]],
    });

    this.loginForm = this._formBuilder.group({
      mobile: [{value: '', disabled: this.isMobileDisabled}],
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });

    // Initialize timer
    this.startTimer();
  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  

  startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    
    this.countdown = 60;
    this.timerSubscription = interval(1000)
      .pipe(takeWhile(() => this.countdown > 0))
      .subscribe(() => {
        this.countdown--;
      });
  }

  requestOTP() {
    this.loading = true;
    this.aspLoginService.getOTP(this.rf.mobile.value).subscribe(
      res => {
        console.log("res",res);
        if (res['code'] && res['code'] === 200) {
          this.enableOTP = true;
          this.loading = false;

          this.loginForm = this._formBuilder.group({
            mobile: [{value: this.rf.mobile.value, disabled: this.isMobileDisabled}],
            otp: ['', [Validators.required, Validators.minLength(6)]]
          });

          this.startTimer(); // Start timer on successful OTP request
        }
      }
    );
  }

  resendOTP() {
    this.startTimer(); // Reset timer
    this.requestOTP();
  }

  verifyOTP() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.aspLoginService.login(this.f.mobile.value, this.f.otp.value).subscribe(
      res => {
        console.log("res",res);
        if (res && res['code'] === 200) {
          let userKey = res['data']['userKey'];
          localStorage.setItem('spareUserKey', userKey); 
          let userType = res['data']['userType'];
          localStorage.setItem('userType', userType); 
          let roleType= res['data']['roleType'];
          localStorage.setItem('roleType', roleType);  
          let token = res['data']['accessToken'];
          localStorage.setItem('spareLoginToken', token);  
          let refreshToken = res['data']['refreshToken'];
          localStorage.setItem('spareRefreshToken', refreshToken);  
          this.toastr.success('You are successfully logged in', 'Login');
          this.dialog.open(AspSwitchGarageComponent);
          this.loading = false;
        } else {    
          this.router.navigate(['login']);  
          this.toastr.error(res['message'], 'Error');
          this.loading = false;
        }
      }
    );
  }

  get rf() { return this.requestOTPForm.controls; }
  get f() { return this.loginForm.controls; }

  changeMobileNumber() {
    this.enableOTP = false;
    this.requestOTPForm.controls['mobile'].setValue(this.loginForm.controls['mobile'].value);
    this.requestOTPForm.controls['mobile'].updateValueAndValidity();
    this.requestOTPForm.controls['mobile'].markAsTouched();

    setTimeout(() => {
      this.mobileInput.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}

