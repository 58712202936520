import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asp-join-autoverse',
  templateUrl: './asp-join-autoverse.component.html',
  styleUrls: ['./asp-join-autoverse.component.scss']
})
export class AspJoinAutoverseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
