<div class="my-request">
   <div [ngClass]="isMobile? 'containerMobile' : 'container'" >
    <div class="sidecontainer" *ngIf="!isMobile" [ngClass]="{'sidecontainerMobile': isMobile}">
      <div class="sidebar" [ngClass]="{'sidebarMobile': isMobile}">
        <h3 >Filters</h3>
        <hr />

        <div class="clear">
          <div *ngIf="hasAppliedFilters()" class="applied-filters">
            <h4>Applied Filters</h4>
            <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
              <span>{{ appliedFilter.option.label }}</span>
              <mat-icon (click)="removeFilter(appliedFilter.orderTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
            </div>
          </div>
          <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">Clear Filters</div>
        </div>

        <div class="filter-sectionMobile">
          <h4 >REQUEST STATUS</h4>
          <div *ngFor="let status of filters.requestStatus" class="filter-option">
            <label >
              <span>{{ status.label }}</span>
              <span class="radio-container">
                <input type="radio" name="requestStatus" [(ngModel)]="selectedRequestStatus" [value]="status.value" (change)="toggleSelection('requestStatus', status)" class="filter-radio">
              </span>
            </label>
          </div>
        </div>
        <hr />

        <div class="filter-sectionMobile">
          <h4 >REQUEST TIME</h4>
          <div *ngFor="let time of filters.requestTime">
            <div *ngIf="time.children; else singleRadio" class="filter-option">
              <h5>{{ time.label }}</h5>
              <div *ngFor="let child of time.children" class="filter-option">
                <label>
                  <span>{{ child.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="requestTime" [(ngModel)]="selectedRequestTime" [value]="child.value" (change)="toggleSelection('requestTime', child)" class="filter-radio">
                  </span>
                </label>
              </div>
            </div>
            <ng-template #singleRadio>
              <label class="filter-option">
                <span>{{ time.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="requestTime" [(ngModel)]="selectedRequestTime" [value]="time.value" (change)="toggleSelection('requestTime', time)" class="filter-radio">
                </span>
              </label>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div  class="main-content" [ngClass]="{'main-contentMobile': isMobile}">
      <div class="search" >
        <input type="text" placeholder="Search your requests here" [(ngModel)]="searchTerm"(keydown.enter)="filterRequest()" (input)="showClearIcon = searchTerm.length > 0">
        <span class="close-button" 
          *ngIf="showClearIcon" (click)="clearSearch()" class="clear-btn" >✖
        </span>
        <button (click)="filterRequest()" *ngIf="!isMobile">🔍 Search Requests</button>
        <button (click)="filterRequest()" *ngIf="isMobile">🔍</button>
        <span  (click)="sidecontainer()" *ngIf="isMobile" class="filterslist">
          <mat-icon style="color: black;">filter_list</mat-icon>
          Filters
        </span>
      </div>
      <div class="overlay" [class.visible]="isSidebarOpen" (click)="sidecontainer()"></div>

      <div *ngIf="isSidebarOpen" class="sidecontainerMobile">
        <div class="sidebarMobile">
          
          <h3 ><i (click)="sidecontainer()" class="material-icons" style="color: black;font-weight: bold;margin-left: 12px;top: 5px;position: relative;">arrow_back</i>Filters</h3>
          <hr />

        <div class="clear" style="    margin-left: 11px;">
          <div *ngIf="hasAppliedFilters()" class="applied-filters">
            <h4>Applied Filters</h4>
            <div *ngFor="let appliedFilter of getAppliedFilters()" class="apply">
              <span>{{ appliedFilter.option.label }}</span>
              <mat-icon (click)="removeFilter(appliedFilter.orderTime, appliedFilter.option)" class="apply1">cancel</mat-icon>
            </div>
          </div>
          <div *ngIf="hasAppliedFilters()" (click)="clearFilters()" class="clear-filters-btn">Clear Filters</div>
        </div>

        <div  Class=filter-sectionMobile>
          <h4 >REQUEST STATUS</h4>
          <div *ngFor="let status of filters.requestStatus" class="filter-option">
            <label >
              <span>{{ status.label }}</span>
              <span class="radio-container">
                <input type="radio" name="requestStatus" [(ngModel)]="selectedRequestStatus" [value]="status.value" (change)="toggleSelection('requestStatus', status)" class="filter-radio">
              </span>
            </label>
          </div>
        </div>
        <hr />

        <div Class=filter-sectionMobile>
          <h4 >REQUEST TIME</h4>
          <div *ngFor="let time of filters.requestTime">
            <div *ngIf="time.children; else singleRadio" class="filter-option">
              <h5>{{ time.label }}</h5>
              <div *ngFor="let child of time.children" class="filter-option">
                <label>
                  <span>{{ child.label }}</span>
                  <span class="radio-container">
                    <input type="radio" name="requestTime" [(ngModel)]="selectedRequestTime" [value]="child.value" (change)="toggleSelection('requestTime', child)" class="filter-radio">
                  </span>
                </label>
              </div>
            </div>
            <ng-template #singleRadio>
              <label class="filter-option">
                <span>{{ time.label }}</span>
                <span class="radio-container">
                  <input type="radio" name="requestTime" [(ngModel)]="selectedRequestTime" [value]="time.value" (change)="toggleSelection('requestTime', time)" class="filter-radio">
                </span>
              </label>
            </ng-template>
          </div>
        </div>
        <div class="applyFilter">
          <button (click)="sidecontainer()" class="applyFilterText">Apply Filter</button>
        </div>
      </div>
    </div>

    <div class="no-image-car">
      <div class="no-img" *ngIf="!loading && filteredRequestList.length === 0">
        <img [src]="emptyList" alt="No data available" />
        <h1 class="no-request-text">No Request Found</h1>
        <div class="eidt-text">Edit filter or go back to my request page</div>
        <button class="go-back-btn" (click)="clearFilters()" >Go to My Requests</button>
      </div>
    </div>

      <div *ngIf="!loading && filteredRequestList.length > 0">
        <div class="header" *ngFor="let list of filteredRequestList">
          <div class="header-row">
            <div class="order-icon" *ngIf="list.requestDetails.make">
                <img [src]="getMakeImageUrl(list.requestDetails.make)" alt="Icon">                
            </div>
            <div class="request-card">
              <div class="car-img" *ngIf="!list.requestDetails.make">
                <div class="car-image-wrapper">
                  <mat-icon class="car-image">directions_car</mat-icon>
                </div>  
                <!-- <div class="request-info">
                  <div class="request-number">Request ID# {{list.id}}</div>
                  <div class="universal-text">Universal</div>
                </div> -->
              </div> 
            </div>
            
            <div class="request-info">
              <div class="header-top-row">
                  <div class="request-number">Request ID# {{list.id}}</div>
                  <div class="quote-approved-container">
                    <div class="quote-approved" [ngClass]="{
                        'requested': getStatusMessage(list.statusDetails) === 'Requested',
                        'quote-received': getStatusMessage(list.statusDetails) === 'Quote Received',
                        'quote-approved': getStatusMessage(list.statusDetails) === 'Quote Approved'
                    }">{{ getStatusMessage(list.statusDetails) }}</div>
                </div>
              </div>
              <ng-container *ngIf="list.requestDetails.model || list.requestDetails.fuelType; else noDetails">
                <div style="font-weight: bold;">
                  {{list.requestDetails.model}}, {{list.requestDetails.fuelType}}
                </div>
                <div class=" variant">
                  {{list.requestDetails.variant}} [{{list.requestDetails.manufacturingYear}}]
                </div>
              </ng-container>
              <div class="registration-number">{{list.requestDetails.regNo}}</div>
              <ng-template #noDetails>
                <div class="universal-text">Universal</div>
              </ng-template>
          </div>
      </div>

          <div class="header-row1">
            <div *ngIf="list.statusDetails">
              <div *ngFor="let status of filters.requestStatus; index as i">
                <div *ngIf="list.statusDetails[status.value]" class="no-of-items">Number of items: {{list.statusDetails[status.value]}}</div>
              </div>
            </div>
          </div>
          <div class="header-row2">
            <div class="request-date">Requested on: {{list.requestDetails.requestedDate | date:'dd MMM yyyy, hh:mm a': 'GMT+1060'}}</div>
            <a class="view-btn" (click)="navigateToDetails(list.id, list.requestDetails.regNo)" >
              <span class="view">View Details</span>
              <i class="material-icons" >arrow_right</i> 
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading">
  <div #dynamicContainers></div>
  </div>