import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class CatAllService {

  constructor(
    private http: HttpClient,
  ) { }

  getCarsList(){
    console.log('-----Get Cars List-----------');
    return this.http.get(`${GlobalVariable.BASE_API_URL_AUTOVERSE}` + '/get-available-cars');
  }
  
  getTreeData(id) {
    console.log('-----Get Tree Data-----------');  
    return this.http.get(`${GlobalVariable.BASE_API_URL_AUTOVERSE}` + '/get-mapping-tree-for-a-car?id='+id);
  }

  getCatalogDetails(pageNumber, maker, model, year, variantId){
    console.log('-----Get Catalog Details-----------');  
    return this.http.get(`${GlobalVariable.BASE_API_URL_AUTOVERSE}` + '/get-car-complete-info?pageNumber='+pageNumber+'&maker='+maker+'&model='+model+'&year='+year+'&variantId='+variantId);
  }

  updateCarInformation(payload: any) {
    return this.http.put(`${GlobalVariable.BASE_API_URL_AUTOVERSE}` + `/update-car-information`, payload);
  }
  
}
  

