import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspOrderListService {

  constructor(
    private http: HttpClient,
  ) { }

  orderList(userKey, garageKey,pageIndex: number = 0, pageSize: number = 250){
    let paramData = {      
      userKey: userKey,
      garageKey: garageKey,
      source: "web" 
    };
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('size', pageSize.toString())
    

    console.log('-----Get Order List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/v2/order/getAllOrders`, paramData, { params });

  }
  getorderdetails(orderKey){
    let param={ 
    };
    console.log('-----Get Order details-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/order/detail/'+orderKey, param);

  }
  getReorder(orderKey){
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/reOrder/' +orderKey);
  }
}
