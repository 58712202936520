// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    firebase: {
        apiKey: 'AIzaSyCod1wW-XWCyXv6vvteiNP6MHOKZJ19H-k',
        authDomain: 'mechanic-app-169508.firebaseapp.com',
        databaseURL: 'https://mechanic-app-169508.firebaseio.com',
        projectId: 'mechanic-app-169508',
        storageBucket: 'mechanic-app-169508.appspot.com',
        messagingSenderId: '573871511180',
        appId: '1:573871511180:web:bb7df46d2139479d69cda9',
    },
    API_VERIFICATION_KEY: 'h6S5lcvELn2XLh8XURiFW1HSynljQywm6lbH1Yf7',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
