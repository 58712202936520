import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})
export class AspPaymentService {


  constructor(
    private http: HttpClient,
  ) { }

  getUserDetails(garageKey){    
    console.log('-----Get User Details-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/address/getAll/'+garageKey);
  }
  createOrder(shippingAddressId, billingAddressId, paymentMethod, razorPayPaymentId, orderKey, cod, grandTotal, walletAmountToUse, walletChecked){
    let userKey = localStorage.getItem('spareUserKey');
    let agentKey = localStorage.getItem('agentKey');
    let rowKey = localStorage.getItem('rowKey') || '';
    let variantKey = localStorage.getItem('variantKey') || '';
    let make = localStorage.getItem('selectedMake');
    let model = localStorage.getItem('selectedModel');
    let registrationNumber = localStorage.getItem('registrationNumber');
    let variant = localStorage.getItem('variant');
    let mfgYear = localStorage.getItem('selectedYear');
    let fuelType = localStorage.getItem('selectedFuel');
    let garageKey = localStorage.getItem('garageKey');


    let cartList = [];
    if (sessionStorage.getItem('addedCart')) {
        let cartData = JSON.parse(sessionStorage.getItem('addedCart'));
        Object.values(cartData[0]).forEach(cart => {
          // Find if an entry with the same rowKey and variantKey already exists
          const existingEntry = cartList.find(
            entry => entry.rowKey === cart['rowKey'] && entry.variantKey === cart['variantKey']
          );
        
          const newItem = {
            kitId: 0, // Assuming a default value for kitId
            partKey: cart['key'],
            quantity: cart['cart_qty']
          };
        
          if (existingEntry) {
            // If found, push the new item to the items array of the existing entry
            existingEntry.items.push(newItem);
          } else {
            // Otherwise, create a new entry
            cartList.push({
              fuelType: cart['selectedFuel'],
              items: [newItem], // Initialize with the new item
              make: cart['selectedMake'],
              model: cart['selectedModel'],
              registrationNumber: cart['registrationNumber'] || "", // Use empty string if not provided
              rowKey: cart['rowKey'],
              variant: cart['variant'],
              variantKey: cart['variantKey'],
              mfgYear: cart['selectedYear']
            });
          }
        });
    }

    let paramData = {      
      billingAddressId: billingAddressId,
      cod: cod,
      data: cartList ,
      garageKey: garageKey,
      // id: agentKey,
      orderKey: orderKey,
      userKey:userKey,
      paymentMethod: paymentMethod,
      razorPayPaymentId: razorPayPaymentId,
      shippingAddressId: shippingAddressId,
      userType: localStorage.getItem('userType'),
      source: "web" 
    };

    // Handle Payment Method Logic
    if (paymentMethod == 1) {
      // COD Payment
      if(walletChecked && walletAmountToUse>0){
        paramData['payment'] = {
          "online": false,
          "onlineAmount": 0.0,
          "onlinePaymentId": '',
          "onlinePaymentMethod": 1,
          "wallet": walletChecked,
          "walletAmount": walletAmountToUse
        };
      }
    } else if (paymentMethod == 2) {
      // Online Payment
      if(walletChecked && walletAmountToUse>0){
        paramData['payment'] = {
          "online": true,
          "onlineAmount": grandTotal,
          "onlinePaymentId": razorPayPaymentId,
          "onlinePaymentMethod": 2,
          "wallet": walletChecked,
          "walletAmount": walletAmountToUse
        };
      }else{
        paramData['payment'] = {
          "online": true,
          "onlineAmount": grandTotal,
          "onlinePaymentId": razorPayPaymentId,
          "onlinePaymentMethod": 2
        };
      }
    }

    console.log('-----Create Order-----------');    
    console.log("paramData",paramData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/v2/order/create', paramData);
    
}


  createRazorPayOrder(orderKey, userKey, garageKey, grandTotal, orderType, userType, agentKey){
    let paramData = {      
      orderKey: orderKey,
      // userKey: userKey,
      garageKey: garageKey,
      amount: grandTotal,
      orderType: orderType,
      agentKey: agentKey,
      userType: userType,
      source: "web" 
    };
    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder');
    // console.log(paramData);
    console.log('-----Create RazorPay Order-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder', paramData);
  
  }

  verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey){
    let paramData = {      
      razorPayPaymentId: razorPayPaymentId,
      razorPayOrderId: razorPayOrderId,
      razorPaySignature: razorPaySignature,
      orderKey: orderKey,
      source: "web" 
    };

    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment');
    // console.log(paramData);
    console.log('-----Verify Payment-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment', paramData);
  
  }

  getWallet(garageKey, userKey) {    
    console.log('-----Get Wallet-----------');
    // return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/payment/getPaymentMethods/' +garageKey);
        return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/payment/methods/' + userKey+'/'+garageKey);

  }
}
