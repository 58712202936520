import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AspMyJobCardsService } from './asp-my-job-cards.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SideBarJobcardComponent } from '../side-bar-jobcard/side-bar-jobcard.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


interface JobCard {
  id: number;
  bookingId: number;
  phoneNumber: string;
  name: string;
  odometer: number;
  rowKey: string;
  variantKey: string;
  make: string;
  model: string;
  carSize: string;
  variant: string;
  fuelType: string;
  registrationNumber: string;
  createdAt: any;
  requestId: number;
}

interface RequestData {
  totalPages: number;
  currentPage: number;
  totalItems: number;
  jobCards: JobCard[];
}

@Component({
  selector: 'app-asp-my-job-cards',
  templateUrl: './asp-my-job-cards.component.html',
  styleUrls: ['./asp-my-job-cards.component.scss']
})
export class AspMyJobCardsComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  request: RequestData | null = { totalPages: 0, currentPage: 0, totalItems: 0, jobCards: [] }; // Default initialization
  formSubmitted: boolean = false;
  isSidebarOpen: boolean = false;
  customerName: string = '';
  customerMobile: string = '';
  isPopupOpen: boolean = false;
  successIcon: string = '../../../../assets/icons/icons8-ok.gif';
  currentText: string = "";
  searchOptions = ["Search By 'Job Card ID'", "Search By 'Name'", "Search By 'Mobile Number'"];
  currentIndex: number = 0;
  textInterval: any;
  searchText: string = '';
  isConfirmationSidebarOpen: boolean = false;
  make: any = '';
  filteredJobCards: JobCard[] = [];
  bookingId: any;
  search: any;
  isVisible:any;
  isMobile: boolean;

  constructor(
    private aspMyJobCardsService: AspMyJobCardsService,
    private resolver: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.fetchJobCards();
    this.scrollText();
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    this.fetchJobCards();
    
    if(localStorage.getItem('jobcard')  && localStorage.getItem('jobcard') == '1'){
      this.loadorderdetails();
    }
  }

  ngOnDestroy() {
    clearInterval(this.textInterval);
  }

  scrollText() {
    this.textInterval = setInterval(() => {
      this.currentText = this.searchOptions[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.searchOptions.length;
    }, 2000);
  }

  onInputChange(): void {
    if (this.searchText.length === 0) {
      this.scrollText();
    } else {
      clearInterval(this.textInterval);
    }
    this.filterRequest();
  }

  clearSearch(): void {
    this.searchText = '';
    this.scrollText();
    this.filteredJobCards = this.request?.jobCards || [];
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      this.isConfirmationSidebarOpen = false;
    }
  }

  fetchJobCards(): void {
    this.loading = true; // Set loading to true when fetching data
  
    const garageKey = localStorage.getItem('garageKey'); // Get garageKey from localStorage
   
  
    this.aspMyJobCardsService.getMyJobCards(garageKey, this.bookingId, this.search).subscribe({
      next: (response: any) => {
        if (response && response.code === 200) {
          this.request = response.data; // Assign the entire response data to the request object
          // Optional: Sort job cards if needed
          if (this.request.jobCards) {
            this.request.jobCards.sort((a: JobCard, b: JobCard) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
            this.filteredJobCards = this.request.jobCards;

          }
        } else {
          console.error('Unexpected response code:', response.code);
        }
        this.loading = false; // Stop loading after data is fetched
      },
      error: (error: HttpErrorResponse) => {
        console.error('API error:', error);
        this.loading = false; // Stop loading on error
      }
    });
  }
  
  onSubmit(form:NgForm): void {
    this.formSubmitted = true;

    if (form.valid) {
      this.toggleSidebar();
      this.formSubmitted = false;
    } else {
      form.controls['odometer'].markAsTouched();
      form.controls['car-number'].markAsTouched();
    }
  }

  openPopup(): void {
    this.formSubmitted = true;
    if (this.customerName && this.customerMobile) {
      this.isPopupOpen = true;
    }
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  toggleConfirmationSidebar(): void {
    this.isConfirmationSidebarOpen = !this.isConfirmationSidebarOpen;
    if (this.isConfirmationSidebarOpen) {
      this.isSidebarOpen = false; // Close other sidebars if needed
    }
  }

  confirmCarDetails(): void {
    if (this.isDataValid()) {
      // this.toggleConfirmationSidebar();
      this.isPopupOpen = true;
    }
  }
  isDataValid(): boolean {
  return this.request?.jobCards?.some(jobCard => jobCard.make && jobCard.model && jobCard.registrationNumber);
}

  shareOnWhatsApp(): void {
    if (this.request && this.request.jobCards && this.request.jobCards.length > 0) {
      const jobCard = this.request.jobCards[0];
      const message = `
        👋 Hello ${jobCard.name},
        
        🛠️ Your job card has been created successfully.
        
        📋 Job Card ID: ${jobCard.bookingId}
        🚗 Odometer: ${jobCard.odometer}  
        📝 Registration Number: ${jobCard.registrationNumber}
        🏷️ Make: ${jobCard.make}  
        🚙 Model: ${jobCard.model}  
        📅 Variant: ${jobCard.variant}  
        📆 MFG Year: ${jobCard.createdAt} 
        ⛽ Fuel: ${jobCard.fuelType} 
        🔑 VIN: ${jobCard.rowKey}  
        
        We will soon send you a quotation for approval.
        
        Best regards,
        AUTOVERSE GARAGE - DEMO
      `;
      const url = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
      alert('Your job card details have been shared on WhatsApp.');
    } else {
      alert('No job card data available to share.');
    }
  }
  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }
  filterRequest() {
    // if (!this.request || !this.request.jobCards) {
    //   this.filteredJobCards = [];
    //   return;
    // }
    // console.log("Complete Data:",this.request.jobCards);
    this.filteredJobCards = this.request.jobCards.filter(jobCard => {
    
      let statusMatch = true; 
      let timeMatch = true; 
      const searchValue = this.searchText.trim().toLowerCase();
      const searchMatch = searchValue ? 
        (jobCard.name.toLowerCase().includes(searchValue) || 
        jobCard.bookingId.toString().includes(searchValue) || 
        jobCard.phoneNumber.includes(searchValue)) 
        : true;
  
      return statusMatch && timeMatch && searchMatch;
    });

    // console.log("Filtered Data:",this.filteredJobCards);
  }
  

  onEnterPress(event: any): void {
    this.filterRequest();

  }


  
     // Reference to the button using ViewChild
  @ViewChild('myButton') myButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;

  // constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterViewInit(): void {
    if(localStorage.getItem('jobcard') == '1'){
      this.loadorderdetails();
    }
  }

  onCreateJobCardClick(): void {
    // console.log('Create Job Card button clicked!');
    // console.log(localStorage.getItem('jobcard'));
    this.loadorderdetails();
 
  }

 
  loadorderdetails(): void {
    // console.log('Job card details loaded and sidebar opened.');
    this.container.clear();
    
    // Dynamically create the component
    const factory = this.resolver.resolveComponentFactory(SideBarJobcardComponent);
    const componentRef = this.container.createComponent(factory);
    
    // Access the component instance
    const componentInstance = componentRef.instance as SideBarJobcardComponent;
  
    // Toggle the form sidebar (instead of the confirmation sidebar)
    if(localStorage.getItem('jobcard')  && localStorage.getItem('jobcard') == '1'){
      // this.loadorderdetails();

    componentInstance.isVisible = false;  // Open form sidebar instead of confirmation sidebar
    componentInstance.isConfirmationSidebarOpen = true; // Ensure confirmation sidebar is closed
  }
  else{
    componentInstance.isVisible = true;  // Open form sidebar instead of confirmation sidebar
    componentInstance.isConfirmationSidebarOpen = false;
  }
    // console.log('Form Sidebar component is opened.');
  }
  
}
  