import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyJobCardsDetailsComponent } from './my-job-cards-details.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// import { AspRequestDetailsComponent } from '../asp-request-details/asp-request-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { AspRequestDetailsModule } from '../asp-request-details/asp-request-details.module';  // Import the module


const routes: Routes = [
  {
    path: 'my-job-cards-details/:id/:bookingId', 
    component: MyJobCardsDetailsComponent
   
  },
];


@NgModule({
  declarations: [
    MyJobCardsDetailsComponent,
    // AspRequestDetailsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule, 
    HttpClientModule,
    BrowserModule,
    AspRequestDetailsModule 
  ],
  // entryComponents: [AspRequestDetailsComponent],
  exports: [MyJobCardsDetailsComponent, RouterModule],
})
export class MyJobCardsDetailsModule { }
