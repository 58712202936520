import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMakeService {

  constructor(
    private http: HttpClient,
  ) { }  

  getMake(){
    let paramData = {      
      deviceId:"postman",
      // source: "web" 
    };

    console.log('-----Get Make List-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/vehicle/getVehicle', paramData);
  }
}
