
<!-- <div class="page-layout blank fullwidth p-12" fusePerfectScrollbar> -->
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <!-- <cdk-virtual-scroll-viewport  itemSize="50" style="width: 100%; "> -->
    
        <div class="scroll-container" >        
        <div class="page-layout blank fullwidth p-12" fusePerfectScrollbar> 
    <!-- <div class="mat-elevation-z8 mt-8">   -->
        
        <div class="container">
            <div class="head-sec">
                <!-- <div id="page-title">{{title}}</div> -->
                <div id="page-title">
                    <select name="selectedCar" id="selectedCar" [(ngModel)]="selectedCar" (ngModelChange)="onSelectChangeCar($event)">
                        <option *ngFor="let car of carList" [value]="car.id">{{car.maker}} {{car.model}} {{car.variantId}}</option>
                    </select>
                </div>
                <div id="page-title-category">
                    <i class="material-icons" name="prev" id="prev" (click)="paginationPage('prev')" style="width:80px">chevron_left</i>
                    {{pageTitle}}
                    <i class="material-icons" name="next" id="next" (click)="paginationPage('next')" style="width:80px">chevron_right</i>
                </div>
                <div id="page-search">
                    <label>Jump To </label>
                    <input type="text" name="pageNumber" id="pageNumber" [value]="pageNumber"/>
                    <button type="button" name="submit" id="submit" (click)="pageSearch()">Submit</button>
                </div>
            </div>

            <div class="image-table-sec">
                <div class="image-inputs">
                    <!-- Hierarchical Menu -->
                    <div class="left-column">
                        
                        <div class="menu">
                          <div *ngFor="let category of res" class="menu-item" >
                            <div *ngIf="activeMenu != category.key" (click)="toggleMenu(category.key)">
                                <mat-icon class="tree-icon">add_box</mat-icon>
                                <span>{{ category.key }}</span>
                            </div>
                            <div *ngIf="activeMenu == category.key" (click)="toggleMenuClose(category.key)">
                                <mat-icon class="tree-icon">indeterminate_check_box</mat-icon>
                                <span>{{ category.key }}</span>
                            </div>
                            <div *ngIf="activeMenu == category.key" class="sub-menu">
                              <div *ngFor="let component of category.values" class="sub-menu-item" (click)="toggleSubMenu(component.pageTitle)">
                                <mat-icon class="tree-icon" *ngIf="activeSubMenu != component.pageTitle">add_box</mat-icon>
                                <mat-icon class="tree-icon" *ngIf="activeSubMenu == component.pageTitle">indeterminate_check_box</mat-icon>
                                <span>{{ component.pageTitle }}</span>
                                <div *ngIf="activeSubMenu === component.pageTitle" class="sub-sub-menu">
                                    <div class="tree-page" *ngFor="let page of component.pageNo" (click)="paginationPage('treePage', page, component.pageTitle )" [ngClass]="{'highlight': selectedPage === page}">Page {{ page }}</div>
                                </div>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                

                <div class="image-container">
                    
                    <div class="toolbarEdit"></div>
                    <div class="im2" *ngIf="selectedBrush === 'smallBrush'"></div>
                    <!-- <div class="im2Medium" *ngIf="selectedBrush === 'mediumBrush'"></div> -->
                    <div class="im2large" *ngIf="selectedBrush === 'largeBrush'"></div>
                    <div id="coordinates">Coordinates: (0, 0)</div>
                    <button  id="editModeToggle" class="edit-icon" >✏ Edit</button>
                    <!-- (click)="attachImageEventListeners()" -->
                    <div *ngIf="isEditMode" class="toolbar">
                        <div class="tool-item" *ngFor="let brush of brushes" (click)="selectBrush(brush)" [ngClass]="{'selected': selectedBrush === brush.name}">
                          <mat-icon>{{ brush.icon }}</mat-icon>
                          <span class="tool-label">{{ brush.label }}</span>
                        </div>
                    </div>
                    <div class="custom-cursor1" [ngStyle]="cursorStyle" *ngIf="isEditMode === isEditMode && selectedBrush === 'smallBrush'"></div>
                    <div class="custom-cursor" [ngStyle]="cursorStyle" *ngIf="isEditMode === isEditMode && selectedBrush === 'largeBrush'"></div>

                    <img #catalogImg id="image" [src]="croppedImageURL" alt="Catalog Image" (load)="updateMarkerPositions()" >
                    <ng-container *ngIf="isEditMode">
                        <span *ngFor="let area of highlightAreas"
                            class="highlight-area"
                            [ngClass]="{
                                'highlight-area1': isEditMode,
                                'highlight-area': !isEditMode
                            }"
                            [style.width.px]="area.newX1 - area.newX"
                            [style.height.px]="area.newY1 - area.newY"
                            [style.left.px]="area.newX"
                            [style.top.px]="area.newY"
                            [attr.data-x]="area.x"
                            [attr.data-y]="area.y"
                            (mousedown)="onMouseDown($event)"
                            id="editModeToggle"
                            >
                            <!-- <span *ngIf="area.refNo==9">width: {{area.newX1}} - {{area.newX}}, height:{{area.newY1}} - {{area.newY}}</span> -->
                        </span>
                    </ng-container>

                    <ng-container *ngIf="!isEditMode">
                        <span *ngFor="let area of highlightAreas"
                            class="highlight-area"
                            [ngClass]="{
                                'highlight-area1': isEditMode,
                                'highlight-area': !isEditMode
                            }"
                            [style.width.px]="area.newX1 - area.newX"
                            [style.height.px]="area.newY1 - area.newY"
                            [style.left.px]="area.newX"
                            [style.top.px]="area.newY"
                            [attr.data-x]="area.x"
                            [attr.data-y]="area.y"
                            (click)="showData(area.refNo)">
                            <!-- <span *ngIf="area.refNo==9">width: {{area.newX1}} - {{area.newX}}, height:{{area.newY1}} - {{area.newY}}</span> -->
                        </span>
                    </ng-container>
                </div>
                <div class="item-details">
                    <table>
                        <tr>
                            <th>REF NO</th>
                            <th>PART NO</th>
                            <th>DESCRIPTION</th>
                            <th>QTY</th>
                            <th>REMARKS</th>
                        </tr>
                        <tr *ngFor="let list of carDetails" [id]="'item-'+list.refNo" [class.active]="list.refNo === activeId">
                            <td>{{list.refNo}}</td>
                            <td>{{list.partNo}}</td>
                            <td>{{list.description}}</td>
                            <td>{{list.qty}}</td>
                            <td>{{list.remarks}}</td>
                        </tr>
                    </table>
                   
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>
</div> 
<!-- </cdk-virtual-scroll-viewport> -->