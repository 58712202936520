<div  [attr.id]="isMobile ? 'loginMobile' : 'login'" class="inner-scroll"  fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
    <div id="login-intro"  [ngClass]="{'login-introMobile': isMobile}">
        <img src="../../../../assets/images/logos/pitstop-white.png" alt="Pitstop Partner Logo" class="logo"  [ngClass]="{'logoMobile': isMobile}">
        <div class="title" [ngClass]="{'titleMobile': isMobile}">Your One-Stop Solution for Genuine Auto Parts</div>
        <div class="description">
            Find High-Quality Parts for All Your Needs - Fast, Reliable, and Affordable!
        </div>
    </div>
    <div id="login-form-wrapper" fusePerfectScrollbar>

        <div id="login-form">
            <div class="title">{{'LOGIN_INFO.TITLE' | translate}}</div>
            <form *ngIf="!enableOTP" [class.loading]="loading" name="requestOTPForm" [formGroup]="requestOTPForm" (ngSubmit)="requestOTP()" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN_INFO.MOBILE_NUMBER' | translate}}</mat-label>
                    <input #mobileInput matInput formControlName="mobile" maxlength="10" >
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error *ngIf="requestOTPForm.get('mobile').hasError('required')">
                        Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="requestOTPForm.get('mobile').hasError('pattern')">
                        Not a valid mobile number
                    </mat-error>
                    <mat-error *ngIf="!requestOTPForm.get('mobile').hasError('pattern') && requestOTPForm.get('mobile').hasError('minlength')">
                        Mobile Number is less than 10 digits
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="accent" class="submit-button" aria-label="Request OTP" [disabled]="requestOTPForm.invalid">
                    Request OTP
                </button>                
            </form>
            
            <form *ngIf="enableOTP" [class.loading]="loading" name="loginForm" [formGroup]="loginForm" (ngSubmit)="verifyOTP()" novalidate>
                <div class="mob">
                <mat-form-field appearance="outline" style="margin-bottom: -25px;">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput formControlName="mobile" maxlength="10">
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <mat-error *ngIf="loginForm.get('mobile').hasError('required')">
                        Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('mobile').hasError('pattern')">
                        Not a valid mobile number
                    </mat-error>
                    <mat-error *ngIf="!loginForm.get('mobile').hasError('pattern') && loginForm.get('mobile').hasError('minlength')">
                        Mobile Number is less than 10 digits
                    </mat-error>
                </mat-form-field>
            </div>
                
                <div class="changeMob">
                    <a class="change-mobile-link" (click)="changeMobileNumber()">Change mobile number</a>
                </div>
                <div class="Mob1">
                <mat-form-field appearance="outline" class="otp-input">
                    <mat-label>OTP</mat-label>
                    <input matInput type="password" formControlName="otp" maxlength="6" [type]="hide ? 'text' : 'password'" autocomplete="false">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    <mat-error *ngIf="loginForm.get('otp').hasError('required')" >
                        OTP is required
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('otp').hasError('minlength')">
                        OTP Must be 6 digits
                    </mat-error>
                </mat-form-field>
            </div>
                <div *ngIf="countdown > 0" class="otp-timer">
                    Not received the OTP <span style="color:  #144579;">{{countdown}} seconds</span>
                </div>
                <div *ngIf="countdown === 0" class="otp-timer">
                    Not received the OTP <a (click)="resendOTP()">Resend OTP</a>
                </div>
                    
                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN" [disabled]="this.loginForm.invalid">
                    Login
                </button>
                
            </form>
            <!-- <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
        </div>
    </div>
</div>
<div *ngIf="loading">
    <div #dynamicContainers></div>
    </div>