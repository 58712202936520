import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatAllService } from '../cat-all.service'; // Import your service here
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'cat-all-popup',
  templateUrl: './cat-all-popup.component.html',
  styleUrls: ['./cat-all-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {
  updateCatalogCords: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private catAllService: CatAllService,
    private toastr: ToastrService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.updateCatalogCords = this._formBuilder.group({
      id: ['', [Validators.required]]
    });
  }
  
  get uf() { return this.updateCatalogCords.controls; }

  onConfirm(): void {
    if (this.updateCatalogCords.invalid) {
      this.updateCatalogCords.markAllAsTouched(); // Mark all controls as touched to show validation messages
      return;
    }

    let refNo = this.updateCatalogCords.get('id')?.value;

    this.getIdByRefNo(refNo);
  }

  onCancel(): void {
    this.dialogRef.close({ confirmed: false });
  }

  getIdByRefNo(refNo){
    console.log(refNo)
    this.catAllService.getCatalogDetails(this.data.pageNumber, this.data.maker, this.data.model, this.data.year, this.data.variantId).subscribe(
      res => {
        let carDetails = res[0].carDetails;
        let exist = 0;
        carDetails.forEach(carInfo =>{
          if(carInfo.refNo==refNo){
            exist = 1;
            this.updateCarInfo(refNo, carInfo.id);
          }
        });

        setTimeout(() => {
          if(exist==0){
            this.toastr.error("Entered referance number "+refNo+" not found.", 'Update Item Cords');
          }
        }, 1000);
        
        
      }
    );
  }

  updateCarInfo(refNo, id){
    
    const payload = {
      id: id,
      refNo: refNo,
      refNoCoordinates: JSON.stringify([
        { x: this.data.topLeft.x, y: this.data.topLeft.y },
        { x: this.data.topRight.x, y: this.data.topRight.y },
        { x: this.data.bottomRight.x, y: this.data.bottomRight.y },
        { x: this.data.bottomLeft.x, y: this.data.bottomLeft.y }
      ])
    };
    this.catAllService.updateCarInformation(payload).subscribe(
      response => {
        this.dialogRef.close({ confirmed: true, id: id, coordinates: this.data });
        this.toastr.success('Updated successful!!', 'Update Item Cords');
        this.router.navigate(['/catalogue']).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      },
      error => {
        let errorMessage = 'An error occurred while updating the information. Please try again.';
        this.toastr.error(errorMessage, 'Update Item Cords');
        this.router.navigate(['/catalogue']).then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    );
  }
}
