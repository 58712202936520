import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})
export class AspModelService {

  constructor(
    private http: HttpClient,
  ) { }

  getModel(){
    let data = sessionStorage.getItem('spareVehicleDetails'); 
    return data;
  }
}
