import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AspProductDetailsComponent } from '../asp-product-details/asp-product-details.component';

@Component({
  selector: 'app-asp-planned-inventory-popup',
  templateUrl: './asp-planned-inventory-popup.component.html',
  styleUrls: ['./asp-planned-inventory-popup.component.scss']
})
export class AspPlannedInventoryPopupComponent implements OnInit {
  addedCart: any[];
  vehicleType:any = localStorage.getItem('vehicleType');
  newData:any={};
  cartList: any[];
  cartCount: number;
  cartSubTotal: number;
  cartGrandTotal: number;
  isCartCount: boolean;
  itemDetails: any = [];


  constructor(
    public dialog:MatDialogRef<AspPlannedInventoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.itemDetails = this.data.itemDetails;
  }

  addItemToCart(count){      
    this.addedCart = [];
    if(this.vehicleType=='universal'){
      this.itemDetails.type = 'universal';
    }
    
    if(sessionStorage.getItem('addedCart')){
      this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
      this.itemDetails.cart_qty = count;
      this.newData[this.itemDetails.key] = this.itemDetails;
      this.addedCart.push(this.newData);
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    }else{
      this.itemDetails.cart_qty = count;
      this.newData[this.itemDetails.key] = this.itemDetails;
      this.addedCart.push(this.newData);
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    }      
    this.getCart();
    this.closePopup();
  }

  
  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
    
    if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 
      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;
        }

        this.cartCount += cart['cart_qty'];
        if(cart['sellingPrice']>0){
          this.cartSubTotal += (cart['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        }
      });      

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;

      if(this.cartCount>0){
        (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
      }else{
        (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
      }
    }
  }

  

  closePopup(){
    this.dialog.close();
  }

}
