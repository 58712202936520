import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
import { MatRadioModule } from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AspPaymentComponent } from './asp-payment.component';
import { MatTooltipModule } from '@angular/material/tooltip';


const routes: Routes = [
  {
      path: 'payment',
      component: AspPaymentComponent,
  },
];

@NgModule({
  declarations: [AspPaymentComponent],
  imports: [    
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    CdkStepperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ApplicationPipesModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatTooltipModule,
  ],  
  exports: [AspPaymentComponent],
})
export class AspPaymentModule { }
