import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [];
    if (localStorage.getItem('roleType') == "autoverse_employee") {
        navigation.push({
            id: "catalogue",
            title: "Catalogue",
            translate: "NAV.CAT.TITLE",
            type: "item",
            icon: "book",
            url: "/catalogue",
        });
    }
    
    // Add other items to the navigation array
    navigation.push(
        // Auto Spare Parts
        {
            id: "auto-spare-parts",
            title: "Buy Parts",
            translate: "NAV.ASP.TITLE",
            type: "item",
            icon: "store",
            url: "/login",
        },
        {
            id: "auto-spare-parts",
            title: "My Orders",
            translate: "NAV.ORDERLIST.TITLE",
            type: "item",
            icon: "list",
            url: "/order-list",
        },
        {
            id: "auto-spare-parts",
            title: "My Request",
            translate: "NAV.MYREQUEST.TITLE",
            type: "item",
            icon: "note",
            url: "/my-request",
        },
        {
            id: "auto-spare-parts",
            title: "My Bookings",
            translate: "NAV.MYBOOKINGS.TITLE",
            type: "item",
            icon: "library_books",
            url: "/my-bookings",
        },
        {
            id: "auto-spare-parts",
            title: "My JOBCARDS",
            translate: "NAV.MYJOBCARDS.TITLE",
            type: "item",
            icon: "work_outline",
            url: "/my-job-cards",
        },
        {
            id: "auto-spare-parts",
            title: "My Reports",
            translate: "NAV.MYREPORTS.TITLE",
            type: "item",
            icon: "insert_chart_outlined",
            url: "/my-reports",
        },
        {
            id: "auto-spare-parts",
            title: "Customer Quotation",
            translate: "NAV.QUOTE.TITLE",
            type: "item",
            icon: "monetization_on",
            url: "/quote",
        },
        {
            id: "auto-spare-parts",
            title: "Join Autoverse",
            translate: "NAV.JOIN.TITLE",
            type: "item",
            icon: "group_work",
            url: "/join",
        }
    );