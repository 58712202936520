import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';
import { keys } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AspCartService {

  constructor(
    private http: HttpClient,
  ) { }

  getUserDetails(garageKey){    
    
    console.log('-----Get User Details-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/address/getAll/'+garageKey);
  }

  getShippingCharges(cartGrandTotal, garageKey){
    let keys = [];
    if(sessionStorage.getItem('addedCart')){ 
      let cartData;
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      Object.keys(cartData[0]).forEach(partKeys => { 
        keys.push(partKeys);
      });
    }
    
    let paramData = {      
      cartValue: cartGrandTotal,
      partKeys: keys,
      garageKey: garageKey,
      source: "web" 
      // addressId: addressId,
    };

    // console.log(paramData);

    console.log('-----Get Shipping charges-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/order/getShippingCharges', paramData);
  }
}
