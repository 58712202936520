import { Component, ElementRef, ViewChild, ComponentFactoryResolver, ViewContainerRef, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AspOrderListService } from '../../auto-spare-parts/asp-order-list/asp-order-list.service';
import { AspOrderDetailsComponent } from '../asp-order-details/asp-order-details.component';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';



interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  orderStatus: FilterOption[];
  orderTime: FilterOption[];
}

@Component({
  selector: 'app-asp-order-list',
  templateUrl: './asp-order-list.component.html',
  styleUrls: ['./asp-order-list.component.scss']
})

export class AspOrderListComponent implements OnInit, AfterViewInit {
  emptyList:any = 'assets/images/no-cart.png';
  @ViewChild('filterSection', { static: false }) filterSection!: ElementRef;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  searchTerm: string = '';
  showClearIcon: boolean = false;
  pageIndex : number = 0;
  pageSize : number = 250;
  totalPages : number = 0;
  length : number = 0;
  

  filters: Filters = {
    orderStatus: [
      { label: 'Created', value: '1', checked: false },
      { label: 'Confirmed', value: '2', checked: false },
      { label: 'On The Way', value: '3', checked: false },
      { label: 'Delivered', value: '5', checked: false }
    ],
    orderTime: [
      { label: 'Today', value: 'TODAY', checked: false },
      { label: 'Yesterday', value: 'YESTERDAY', checked: false },
      { label: 'Last 7 days', value: 'LAST_7_DAYS', checked: false },
      { label: 'Last 30 days', value: 'LAST_30_DAYS', checked: false },
      { label: 'Last Month', value: 'LAST_MONTH', checked: false },
      { label: 'Any Time', value: 'ANY_TIME', checked: false }
    ]
  };

  loading: boolean = false;
  orderList: any[] = [];
  filteredOrderList: any[] = [];
  // searchTerm: string = '';
  selectedOrderStatus: string = '';
  selectedOrderTime: string = '';
  selectedOrder: any = null;
  isSidebarVisible: boolean = false;
  orderDeliveryTimeFormatted: string;
  isMobile: boolean;
  isSidebarOpen: boolean = false;
  list: any;
  hideScrollText: boolean= false;
  

  constructor(
    private aspOrderListService: AspOrderListService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    localStorage.removeItem('selectedRequestStatus');
    localStorage.removeItem('selectedRequestTime');
    this.getList();
  }
  handlePageEvent(event: any) {
    
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getList();
  }

  // ngAfterViewInit(): void {
  //   this.loadorderdetails();
  // }



  loadorderdetails(orderKey) {
    
    const factory = this.resolver.resolveComponentFactory(AspOrderDetailsComponent);
    const componentRef = this.container.createComponent(factory);

    // Access the instance and call the method
    const componentBInstance = componentRef.instance as AspOrderDetailsComponent;
    componentBInstance.closeSidebar(true);
    componentBInstance.getOrderDetails(orderKey);

  }
ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }

  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   

    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      

      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  sidecontainer() {
    this.isSidebarOpen = !this.isSidebarOpen; // Toggle the sidebar open/close state
  }

  toggleSelection(orderTimeKey: keyof Filters, option: FilterOption) {
    if (orderTimeKey === 'orderStatus') {
      this.filters.orderStatus.forEach(status => {
        status.checked = (status.value === option.value);
      });
      this.selectedOrderStatus = option.value;
    } else if (orderTimeKey === 'orderTime') {
      this.filters.orderTime.forEach(time => {
        time.checked = (time.value === option.value);
        if (time.children) {
          time.children.forEach(child => {
            child.checked = (child.value === option.value);
          });
        }
      });
      this.selectedOrderTime = option.value;
    }
    this.filterOrders();
  }

  clearFilters() {
    this.selectedOrderStatus = '';
    this.selectedOrderTime = '';
    const clearOptions = (options: FilterOption[]) => {
      options.forEach(option => {
        option.checked = false;
        if (option.children) {
          clearOptions(option.children);
        }
      });
    };
  
    (Object.keys(this.filters) as (keyof Filters)[]).forEach(orderTimeKey => {
      clearOptions(this.filters[orderTimeKey]);
    });
    this.filterOrders();
  }
  
  hasAppliedFilters(): boolean {
    return this.selectedOrderStatus !== '' || this.selectedOrderTime !== '';
    
  }

  getAppliedFilters(): { orderTime: keyof Filters, option: FilterOption }[] {
    const appliedFilters: { orderTime: keyof Filters, option: FilterOption }[] = [];

    const addFilter = (orderTimeKey: keyof Filters, option: FilterOption) => {
      if (option.checked) {
        appliedFilters.push({ orderTime: orderTimeKey, option });
      }
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(orderTimeKey => {
      this.filters[orderTimeKey].forEach(option => {
        addFilter(orderTimeKey, option);
        if (option.children) {
          option.children.forEach(child => {
            addFilter(orderTimeKey, child);
          });
        }
      });
    });

    return appliedFilters;
  }

  removeFilter(orderTimeKey: keyof Filters, option: FilterOption) {
    option.checked = false;
    if (orderTimeKey === 'orderStatus') {
      this.selectedOrderStatus = '';
    } else if (orderTimeKey === 'orderTime') {
      this.selectedOrderTime = '';
    }
    this.filterOrders();
  }

  getList() {
    this.loading = true;
    let spareUserKey = localStorage.getItem('spareUserKey');
    let garageKey = localStorage.getItem('garageKey');
    this.aspOrderListService.orderList(spareUserKey, garageKey,this.pageIndex , this.pageSize).subscribe(
      result => {
        console.log("order-list-response",result)
        let res = JSON.parse(JSON.stringify(result)); 
        this.length = res.data.orders.totalItems;
        this.totalPages = res.data.orders.totalPage;
        this.pageIndex = res.data.orders.currentPage;
  
        if(res.code === 200){          
          this.loading = false;
          res.data.orders.data.forEach(item => {
            item.titleName = this.formatOrderDeliveryTime(item.orderConfirmTime, item.orderDeliveryTime);
          });
          this.orderList = res.data.orders.data;

          this.filteredOrderList = res.data.orders.data;

          // console.log(this.orderList);
        } else {
          this.loading = false;
        }
      }
    );
  }
 

  filterOrders() {
  
    this.filteredOrderList = this.orderList.filter(order => {
      const statusMatch = this.selectedOrderStatus ? order.status.toString() === this.selectedOrderStatus : true;
      const timeMatch = this.selectedOrderTime ? this.filterByTime(order.createdAt, this.selectedOrderTime) : true;
      const searchMatch = this.searchTerm.trim() ? order.orderId.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;
      const searchRegNo = this.searchTerm.trim()? order.registrationNumber?.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;
      const requestId = this.searchTerm.trim()? order.requestId?.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;
      const make = this.searchTerm.trim()? order.make?.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;
      const model = this.searchTerm.trim()? order.model?.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;
      const fuelType = this.searchTerm.trim()? order.fuelType?.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase()) : true;

      return statusMatch && timeMatch && (searchMatch || searchRegNo ||requestId || make || model || fuelType) ;
    });
  
  }
  
  filterByTime(orderTime: string, filter: string): boolean {
    const now = new Date();
    const orderDate = new Date(orderTime);

    switch (filter) {
      case 'TODAY':
        // Check if the orderDate is the same day as today
        return orderDate.toDateString() === now.toDateString();
        
      case 'YESTERDAY':
        // Check if the orderDate is the same day as yesterday
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return orderDate.toDateString() === yesterday.toDateString();
        
      case 'LAST_7_DAYS':
        // Check if the orderDate is within the last 7 days
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return orderDate >= weekAgo && orderDate <= now;
        
      case 'LAST_30_DAYS':
        // Check if the orderDate is within the last 30 days
        const monthAgo = new Date(now);
        monthAgo.setDate(now.getDate() - 30);
        return orderDate >= monthAgo && orderDate <= now;
        
      case 'LAST_MONTH':
        // Check if the orderDate is within the last month
        const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        return orderDate >= lastMonthStart && orderDate <= lastMonthEnd;
        
      case 'ANY_TIME':
      default:
        return true;
    }
  }

  viewDetails(order: any) {
    this.selectedOrder = order;
    this.isSidebarVisible = true;
  }
  clearSearch() {
    this.searchTerm = '';
    this.showClearIcon = false;
    this.hideScrollText = false;
    this.filterOrders();
  }
  formatOrderDeliveryTime(confirmTime: string, deliveryTime: string) {
    try {
      const confirmDate = new Date(confirmTime.replace(" ", "T"));
      const deliveryDate = new Date(deliveryTime.replace(" ", "T"));

      if (isNaN(confirmDate.getTime()) || isNaN(deliveryDate.getTime())) {
        return '0 minutes';
        
      }

      const diffMs = deliveryDate.getTime() - confirmDate.getTime();
      const diffMins = Math.floor(diffMs / 60000);

      if (diffMins < 0) {
        return '0 minutes';
        
      }

      if (diffMins <= 180) {
        return `${diffMins} minutes`;
      } else {
        const hours = Math.floor(diffMins / 60);
        const minutes = diffMins % 60;
        return `${hours}H ${minutes} min`;
      }

    } catch (error) {
      return '0 minutes';
    }
}
// In your component's TypeScript file
getTotalAmount(): number {
  return this.list.grandTotal + (this.list.shippingCharges > 0 ? this.list.shippingCharges : 0);
}

}


