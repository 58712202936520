import { NgModule } from '@angular/core';
import { RemovewhitespacesPipe } from './removewhitespaces.pipe';
import { FilterNames } from './filter-names';
import { FilterBrand } from './filter-brand';
@NgModule({
  imports: [
  ],
  declarations: [
    RemovewhitespacesPipe,
    FilterNames,
    FilterBrand,
  ],
  exports: [RemovewhitespacesPipe, FilterNames, FilterBrand]
})
export class ApplicationPipesModule {
}
