import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AspQuotationListComponent} from './asp-quotation-list.component';
const routes: Routes = [
  {
      path: 'quote',
      component: AspQuotationListComponent,
  },
];

@NgModule({
  declarations: [AspQuotationListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports:[AspQuotationListComponent, RouterModule]
})
export class AspQuotationListModule { }
