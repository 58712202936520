import { NgModule } from '@angular/core';
import { CatAllModule } from './cat-all/cat-all.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [],
  imports: [
    MatProgressBarModule,
    CatAllModule,
  ]
})
export class CatalogueModule { }
