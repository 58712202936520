<div class="sidebar1" [class.visible]="isVisible1" >
    <div class="SearchBarContainer"  >
        <span (click)="$event.stopPropagation()">
        <i class="materialArrow" (click)="clearSearchbar($event)">arrow_back</i>
    </span>
        <div class="SearchBarButton" (click)="toggleSearch()">
          <div class="SearchBarIcon">
            <i class="material-icons">search</i>
          </div>
          <div class="SearchBarPlaceholder">
            <div class="SearchBarAnimation" *ngIf="!isInputVisible">
              <div id="animationText-1" class="SearchBarAnimationText">Search By "Part Name"</div>
              <div id="animationText-2" class="SearchBarAnimationText">Search By "Part Number"</div>
              <div id="animationText-3" class="SearchBarAnimationText">Search By "Brand"</div>
              <div id="animationText-4" class="SearchBarAnimationText">Search By "Category"</div>
            </div>
      
            <div *ngIf="isInputVisible" class="SearchInputContainer" (click)="$event.stopPropagation()">
                <input
                  type="text"
                  placeholder="Type your search"
                  class="SearchInput"
                  [(ngModel)]="searchText"
                  (input)="filterSuggestions()"
                  (click)="toggleSearch()"
                >
                <button (click)="clearSearch($event)" class="button">
                  <i class="material-icons" style="font-size: 16px;">clear</i>
                </button>
                
            </div>
        </div>
    </div>
</div>
        
                <!-- Suggestions Dropdown -->
                <div #dropdown class="suggestions1" *ngIf="suggestions.length > 0">
                    <div (click)="$event.stopPropagation()" >
                  <div *ngFor="let suggestion of suggestions; let i = index" (click)="selectSuggestion(suggestion)" class="selectSuggestion">
                    
                    <i class="material-icons" *ngIf="isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">access_time</i>
                    <i class="material-icons" *ngIf="!isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">search</i>
                    <span [innerHTML]="getHighlightedText(suggestion.value, searchText)"></span>
                </div>
              
            </div>
      
      </div>

</div>