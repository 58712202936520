import { Component, OnInit } from '@angular/core';
import { AspMyReportsService} from './asp-my-reports.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-asp-my-reports',
  templateUrl: './asp-my-reports.component.html',
  styleUrls: ['./asp-my-reports.component.scss']
})
export class AspMyReportsComponent implements OnInit {
  data: any;
  datatoday: any;
  isMobile: boolean;

  constructor(
    private aspMyReportsService:AspMyReportsService, 
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }
    
  ngOnInit(): void {
   this.getDashboard();
   this.getDashboardToday();
  }
  getDashboard(){
    const garageKey = localStorage.getItem('garageKey'); 
    const userKey = localStorage.getItem('spareUserKey'); 
    const monthly= true;

    this.aspMyReportsService.getDashboard(garageKey,userKey,monthly).subscribe(
      res => {
        this.data=res['data'];
      }
    
    );
  }
  getDashboardToday(){
    const garageKey = localStorage.getItem('garageKey'); 
    const userKey = localStorage.getItem('spareUserKey'); 
    const monthly= false;

    this.aspMyReportsService.getDashboard(garageKey,userKey,monthly).subscribe(
      res => {
        this.datatoday=res['data'];
      }
    
    );
  }
  getYesterdayDate(): string {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Format the date as 'dd MMM yyyy'
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    return yesterday.toLocaleDateString('en-GB', options);
  }
}
