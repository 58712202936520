<div id="switch-garage" class="inner-scroll" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
    <div id="switch-garage-form-wrapper" fusePerfectScrollbar>
        <mat-icon style="text-align: right; width: 100%; cursor: pointer;" 
        (click)="closePopup()" 
        *ngIf="garageKey">close</mat-icon>

        <div class="order-container">
            <div>
            <header>
               <div class="back-button">
                  
                  <div class="title">Select Garages</div>
               </div>
               
                
            </header>
            
               
                  <div style="padding: 0px 8px;">
                     <input
                        type="text" class="SearchInput" placeholder="Search Garages..." [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()" >
                     <button (click)="clearSearch()" class="button">
                        <i class="material-iconsClear" >clear</i>
                     </button>
                  </div>
               </div>
               <div id="switch-garage-form">
               <div *ngFor="let list of filteredGarage" style="display: grid;padding: 0px 10px;">
                  
                   <div class="garage-name" (click)="selectGarage(list.garageKey, list.name ,list.contactEmail, list.contactPhone, list.id)">{{list.name}}</div>
               </div>
           </div>
         </div>
    </div>
</div>
