import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SideBarJobcardService } from './side-bar-jobcard.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-side-bar-jobcard',
  templateUrl: './side-bar-jobcard.component.html',
  styleUrls: ['./side-bar-jobcard.component.scss'],
})
export class SideBarJobcardComponent implements OnInit, OnChanges {

  selectedMake: string;
  selectedModel: string;
  selectedYear: string;
  selectedVariant: string;

  vehicleForm1: FormGroup;
  formSubmitted = false;
  loading = false;
  isVisible = false; // Initially set to false to hide the "Find My Car" sidebar
  isConfirmationSidebarOpen = false;
  jobCard: any = {}; // Store car details here
  @Input() bookingId: number | null = null;
  @Input() name: string | null = null;
  @Input() phoneNumber: string | null = null;

  // Other properties
  garageKey: any;
  customerName: string;
  registrationNumber: string;
  variantKeyValue: any;
  currentUserId: any;
  rowKeyValue: any;
  isJobCardCreatedPopupVisible: boolean = false;
  successIcon: string;
  date: any;
  jobCardId: any;
  isMobile: boolean;



  constructor(
    private fb: FormBuilder,
    private sideBarJobcardService: SideBarJobcardService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';

    // Initialize form with phoneNumber and name from Input properties
    this.vehicleForm1 = this.fb.group({
       regNo: [''],
      odometer: ['', [Validators.required]],
      rowKey: [''],
      variantKey: [''],
      phoneNumber: [this.phoneNumber || '', [Validators.required, Validators.minLength(10)]],
      name: [this.name || '', Validators.required]
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Detect if it's a reload
        if (event.navigationTrigger === 'popstate') {
          // Clear localStorage if the page was reloaded
          this.clearLocalStorage();
        }
      }
    });


if (!this.bookingId ){
    // Fetch details from localStorage
    this.selectedMake = localStorage.getItem('selectedMake') || '';
    this.selectedModel = localStorage.getItem('selectedModel') || '';
    this.selectedVariant = localStorage.getItem('variant') || '';
    this.selectedYear = localStorage.getItem('selectedYear') || '';

    this.jobCard = {
      make: this.selectedMake,
      model: this.selectedModel,
      variant: this.selectedVariant,
      mfgYear: this.selectedYear,
      fuelType: localStorage.getItem('fuelType') || '',
      vin: localStorage.getItem('vin') || ''
    };

    // Automatically populate the form
    this.vehicleForm1.patchValue({
      make: this.selectedMake,
      model: this.selectedModel,
      variant: this.selectedVariant,
      year: this.selectedYear
    });
  }
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneNumber && changes.phoneNumber.currentValue) {
      this.vehicleForm1.patchValue({
        phoneNumber: changes.phoneNumber.currentValue
      });
    }
    if (changes.name && changes.name.currentValue) {
      this.vehicleForm1.patchValue({
        name: changes.name.currentValue
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isVisible) {
      this.closeSidebar(true);
      this.isVisible = true;
    }
  }

  onCreateJobCardClick(): void {
    this.isVisible = true;
  }


  onRegDecodeSubmit(): void {
    this.formSubmitted = true;
    this.vehicleForm1.markAllAsTouched();
    if (this.vehicleForm1.valid) {
      this.loading = true;
      const regNo = this.vehicleForm1.get('regNo')?.value.replace(/\s/g, '');
      const odometer = this.vehicleForm1.get('odometer')?.value;
      const phoneNumber = this.vehicleForm1.get('phoneNumber')?.value;
      const name = this.vehicleForm1.get('name')?.value;

      this.sideBarJobcardService.regDecode(regNo).subscribe(
        (res: any) => {
          this.loading = false;
          this.jobCard = {
            make: res.data.make,
            model: res.data.model,
            variant: res.data.variant,
            mfgYear: res.data.year,
            fuelType: res.data.fuel,
            vin: res.data.vin,
          };

          this.vehicleForm1.patchValue({
            rowKey: res.data.rowKey,
            variantKey: res.data.variantKey,
          });

          
          this.isVisible = false;
          this.isConfirmationSidebarOpen = true;
        },
        (error: any) => {
          console.error('Error:', error);
          this.loading = false;
        }
      );
    } else {
      this.vehicleForm1.markAllAsTouched();
    }
  }

  closeSidebar(ishide: boolean): void {
    this.isVisible = ishide;
    this.isConfirmationSidebarOpen = false;
    if (localStorage.getItem('jobcard') && localStorage.getItem('jobcard') === '1') {
      localStorage.removeItem('jobcard');
      localStorage.removeItem('rowKey');
      localStorage.removeItem('selectedMake');
      localStorage.removeItem('selectedModel');
      localStorage.removeItem('selectedYear');
      localStorage.removeItem('variant');
      localStorage.removeItem('variantKey');
      localStorage.removeItem('fuelType');
      localStorage.removeItem('name');
      localStorage.removeItem('odometer');
      localStorage.removeItem('phoneNumber');
  
     
    }
  }

  toggleConfirmationSidebar(): void {
    this.isConfirmationSidebarOpen = !this.isConfirmationSidebarOpen;
    if (!this.isConfirmationSidebarOpen) {
      this.isVisible = true;
    }
  }

  confirmCarDetails(): void {

    const name = localStorage.getItem('name') || this.vehicleForm1.get('name')?.value;
    const phoneNumber = localStorage.getItem('phoneNumber') || this.vehicleForm1.get('phoneNumber')?.value;
    const odometer = localStorage.getItem('odometer') || this.vehicleForm1.get('odometer')?.value;
    const garageKey = localStorage.getItem('garageKey');
    const userId = localStorage.getItem('spareUserKey');
    const rowKey = localStorage.getItem('rowKey')||this.vehicleForm1.get('rowKey')?.value;
    const variantKey = localStorage.getItem('variantKey')||this.vehicleForm1.get('variantKey')?.value;
  

    this.sideBarJobcardService.createbookingId(
      this.bookingId,
      garageKey,
      name, 
      odometer, 
      phoneNumber, 
      this.vehicleForm1.get('regNo')?.value,
      rowKey,
      userId,
      variantKey
    ).subscribe(
      (response: any) => {
        if (response && response.code === 200) {
          this.jobCardId = response.data.jobCardId;
          this.date = response.data.createdAt;
          this.isConfirmationSidebarOpen = false;
          this.isJobCardCreatedPopupVisible = true;
          this.clearLocalStorage();
        } else {
          console.error('Unexpected response from API:', response);
        }
      },
      (error: any) => {
        console.error('Error:', error);
        alert('Failed to create booking due to a server error.');
      }
    );
  }

  clearLocalStorage(): void {
    localStorage.removeItem('jobcard');
    localStorage.removeItem('rowKey');
    localStorage.removeItem('selectedMake');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('fuelType');
    localStorage.removeItem('name');
    localStorage.removeItem('odometer');
    localStorage.removeItem('phoneNumber');
  }
  
  

  closeJobCardPopup() {
    this.isJobCardCreatedPopupVisible = false;
  }

  shareOnWhatsApp(): void {
    if (this.jobCard || this.jobCardId) {
      const message = `
        👋 Hello ${this.vehicleForm1.get('name')?.value || 'Customer'},
        🛠️ Your job card has been created successfully.
        📋 Job Card ID: ${this.jobCardId || 'N/A'}
        🚗 Odometer: ${this.vehicleForm1.get('odometer')?.value || 'N/A'}  
        📝 Registration Number: ${this.vehicleForm1.get('regNo')?.value || 'N/A'}
        🏷️ Make: ${this.jobCard.make || 'N/A'}
        🚙 Model: ${this.jobCard.model || 'N/A'}
        📅 Variant: ${this.jobCard.variant || 'N/A'}
        📆 MFG Year: ${this.jobCard.mfgYear || 'N/A'}
        ⛽ Fuel: ${this.jobCard.fuelType || 'N/A'}
        🔑 VIN: ${this.jobCard.vin || 'N/A'}
        We will soon send you a quotation for approval.
        Best regards, AUTOVERSE GARAGE - DEMO
      `;
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    } else {
      alert('No booking data available to share.');
    }
  }


isManualSelectionValid(): boolean {
  const name = this.vehicleForm1.get('name')?.value;
  const phoneNumber = this.vehicleForm1.get('phoneNumber')?.value;
  const odometer = this.vehicleForm1.get('odometer')?.value;

  return !!(name && phoneNumber && odometer && phoneNumber.length === 10);
}

redirectToManualSelection(): void {
  this.formSubmitted = true;
  this.vehicleForm1.markAllAsTouched();
  if (this.isManualSelectionValid()) {

    localStorage.setItem('name', this.vehicleForm1.get('name')?.value);
    localStorage.setItem('phoneNumber', this.vehicleForm1.get('phoneNumber')?.value);
    localStorage.setItem('odometer', this.vehicleForm1.get('odometer')?.value);
    localStorage.setItem('jobcard', '1');
    this.router.navigate(['/make']);
    this.isConfirmationSidebarOpen = true;
  } else{
    this.vehicleForm1.markAllAsTouched(); 
    // }
}
}
}
