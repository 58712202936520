// import { Component, OnInit } from '@angular/core';
import{MyJobCardsDetailsService} from './my-job-cards-details.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
// import { AspMyRequestService } from '../asp-my-request/asp-my-request.service';
import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AspRequestDetailsComponent } from '../asp-request-details/asp-request-details.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';






@Component({
  selector: 'app-my-job-cards-details',
  templateUrl: './my-job-cards-details.component.html',
  styleUrls: ['./my-job-cards-details.component.scss']
})
export class MyJobCardsDetailsComponent implements OnInit {
  @ViewChild('myDynamicSec', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  
  activeTab: string = 'quoteReceived';
  sidebarOpen: boolean = false;
  quotationReceived: any[] = [];
  quotationApproved: any[] = [];
  itemDetails: any;
  selectedItemsCount: number = 0;
  totalSelectedPrice: number = 0;
  request: any;
  jobCard: any; 
  booking: any;
  loading: boolean;
  jobCardId: number | null = null;
  bookingId: number;
  viewContainerRef: any;
  isMobile: boolean;
  

  constructor(
    private myJobCardsDetailsService: MyJobCardsDetailsService,
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private location: Location,
    
    // private aspMyRequestService: AspMyRequestService,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    
    this.jobCardId = Number(this.route.snapshot.paramMap.get('id'));
    this.bookingId = Number(this.route.snapshot.paramMap.get('bookingId'));
    this.initializeDummyData();
    this.fetchJobCardDetails();
    // this.fetchMyRequest(); 
    


  }


  initializeDummyData() {
    this.quotationReceived = [
      { name: 'Lower Arm - LH', price: 1000, quantity: 1, selected: false, brand: 'Brand A', availability: 'In Stock', mrpUnit: 1200, mrpTotal: 1200, sellingPriceUnit: 1000, totalSellingPrice: 1000, discount: 0, partNumber: '12345' },
      { name: 'Lower Arm - RH', price: 1200, quantity: 2, selected: false, brand: 'Brand B', availability: 'In Stock', mrpUnit: 1300, mrpTotal: 1300, sellingPriceUnit: 1200, totalSellingPrice: 2400, discount: 5, partNumber: '54321' },
    ];

    this.quotationApproved = [
      { name: 'Suspension Kit', price: 3000, quantity: 1 }
    ];
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  openSidebar(item: any): void {
    this.sidebarOpen = true;
    this.itemDetails = item;
  }

  closeSidebar(): void {
    this.sidebarOpen = false;
  }

  selectAll(event: any): void {
    const isChecked = event.target.checked;
    this.quotationReceived.forEach(item => item.selected = isChecked);
    this.updateTotalSellingPrice();
  }

  updateTotalSellingPrice(): void {
    this.selectedItemsCount = this.quotationReceived.filter(item => item.selected).length;
    this.totalSelectedPrice = this.quotationReceived.reduce((acc, item) => item.selected ? acc + item.price * item.quantity : acc, 0);
  }

  anyItemSelected(): boolean {
    return this.quotationReceived.some(item => item.selected);
  }

  approveQuote(): void {
  }

  getMakeImageUrl(make: string | undefined | null): string {
    if (!make) {
      console.warn('Car make is undefined or null. Returning default image.');
      return 'https://cube.getpitstop.com/assets/img/default.png'; // Ensure this URL points to a valid default image
    }
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }


  shareOnWhatsApp(): void {
    if (!this.request) {
      // console.warn('Request object is undefined or null:', this.request);  
      alert('No job card data available to share.');
      return;
    }
  
    if (!this.request.jobCard) {
      // console.warn('Job card is missing:', this.request.jobCard); 
      alert('No job card data available to share.');
      return;
    }
    
  
    const jobCard = this.request.jobCard;
  
    const message = `
      👋 Hello ${jobCard.name},
      
      🛠 Your job card has been created successfully.
      
      📋 Job Card ID: ${jobCard.id}
      🚗 Odometer: ${jobCard.odometer}  
      📝 Registration Number: ${jobCard.registrationNumber}
      🏷 Make: ${jobCard.make}  
      🚙 Model: ${jobCard.model}  
      📅 Variant: ${jobCard.variant}  
      📆 MFG Year: ${jobCard.mfgYear || 0} 
      ⛽ Fuel: ${jobCard.fuelType} 
      🔑 VIN: ${jobCard.vin || ""} 
      
      We will soon send you a quotation for approval.
      
      Best regards,
      ${localStorage.getItem('spareSelectedGarageName') || 'Garage'}
    `;
  
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    // const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    alert('Your job card details have been shared on WhatsApp.');
  }


  fetchJobCardDetails(): void {
    this.loading = true;
    const garageKey = localStorage.getItem('garageKey');
      
    this.myJobCardsDetailsService.getJobCardDetails(this.bookingId , garageKey, this.jobCardId).subscribe(
      (result: any) => {
        this.loading = false;
  
        if (result && result.code === 200) {
          this.request = result.data;



          // Check if request-id is present in the response
        const requestId = this.request?.jobCard?.requestId; // Update this path as per your actual response structure

        if (requestId) {
        
          this.loadComponentB(this.request?.jobCard); // Pass the request-id to Component B
        } else {
          // console.warn('No "request-id" found in job card details');
        }


        } else {
          // console.warn('Unexpected response format or error code:', result);
        }
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        // console.error('Error fetching job card details:', error);
      }
    );
  }
  // fetchMyRequest(): void {
  //   const spareUserKey = localStorage.getItem('spareUserKey');
  //   const garageKey = localStorage.getItem('garageKey');

  //   this.aspMyRequestService.myRequest(spareUserKey, garageKey).subscribe(
  //     result => {
  //       const res = JSON.parse(JSON.stringify(result));
  //       if (res.code === 200) {
  //         // Process the response data as needed
  //         console.log('My Request Data:', res.data);
  //       } else {
  //         console.warn('Error fetching My Request data:', res.message);
  //       }
  //     },
  //     (error: HttpErrorResponse) => {
  //       console.error('Error fetching My Request:', error);
  //     }
  //   );
  // loadComponentB(requestId: number) {
  //   // Create the factory for Component B
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AspRequestDetailsComponent);

  //   // Clear the container
  //   this.container.clear();

  //   // Add the component to the container
  //   this.container.createComponent(componentFactory);
  //   // Logic to dynamically load Component B and pass requestId
  // const componentRef = this.viewContainerRef.createComponent(AspRequestDetailsComponent);
  
  // // Pass the requestId to Component B
  // componentRef.instance.requestId = requestId;
  // }

  loadComponentB(jobCard): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AspRequestDetailsComponent);
    const componentRef = this.container.createComponent(componentFactory);
  
    // const componentRef = this.viewContainerRef.createComponent(componentFactory);

    const instance = componentRef.instance as AspRequestDetailsComponent;
    instance.requestId = jobCard.requestId;  
    instance.jobCard = jobCard;  
}

goBack(): void {
  this.location.back();
}

}
