import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-asp-confirm-order-popup',
  templateUrl: './asp-confirm-order-popup.component.html',
  styleUrls: ['./asp-confirm-order-popup.component.scss'],
  providers: [ DatePipe ]
})
export class AspConfirmOrderPopupComponent implements OnInit {
  successIcon: any;
  order:any;
  sidebarOpen: boolean;
  isMobile: boolean;
 

  constructor(
    private router: Router,
    private date: DatePipe,
    private breakpointObserver: BreakpointObserver,
    public dialog:MatDialogRef<AspConfirmOrderPopupComponent>
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); 
    dialog.disableClose = true;
   }

  ngOnInit(): void {    
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    this.order = JSON.parse(sessionStorage.getItem('order'));
    this.order.orderDate = new Date(this.order.orderDate+' UTC');
    this.order.orderDate = this.date.transform(this.order.orderDate, 'MMM dd yyyy hh:mm a');
    this.clearLocalStorage();
   
    if(!this.isMobile){
      (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
    }else{
      (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
    }
  }

  closePopup(){
    this.dialog.close();
  }

  goToOrder(){
    this.closePopup();
    this.router.navigate(['order-list']);
  }

  private clearLocalStorage(){
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('prepayUpiDiscount');

    localStorage.removeItem('selectedMake');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');
    sessionStorage.removeItem('addedCart');
    sessionStorage.removeItem('sparePartsDetails');

    // razorpay
    sessionStorage.removeItem('rzp_device_id');
    sessionStorage.removeItem('isShippingCharges');
    sessionStorage.removeItem('rzp_checkout_anon_id');


    
    
  };
  

}
