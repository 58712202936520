

   <div class="header">
    <h2 class="head1">Compatible Cars</h2>
    <span>
      Select the car this part is compatible with
      <i class="material-icons" (click)="closePopup()" style="margin-left: 143px; float: right;">close</i>
    </span>
  </div>
  
  <div class="content">
    <div class="car-make">
      <div class="car-make-details">
        <div *ngFor="let item of vehicle">
          <div class="item">
            <label>Car Make?</label>
            <img [src]="item.logo" alt="logo">
            <p class="logoName">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="car-model">
      <label>Car Model?</label>
      <div *ngFor="let item of vehicle">
        <div class="item">
          <div *ngFor="let item1 of item.models" class="model">
            <span 
            #modelSpan 
            (click)="getYear(item1.rowKey)" 
            *ngIf="selectedModel === item1.rowKey"
            class="selected"
          >
            {{item1.model}}
          </span>
          <span 
            #modelSpan 
            (click)="getYear(item1.rowKey)" 
            *ngIf="selectedModel !== item1.rowKey"
          >
            {{item1.model}}
          </span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="manufacturing-year">
      <label>Manufacturing Year?</label>
      <div class="years">
        <div *ngFor="let year of yearList" class="model">
          <span 
        #yearButton 
        (click)="getFuelType(year)"
        *ngIf="selectedYear === year"
        class="selected"
      >
        {{ year }}
          </span>
      <span  
        #yearButton 
        (click)="getFuelType(year)"
        *ngIf="selectedYear !== year"
      >
        {{ year }}
      </span>
        </div>
      </div>
    </div>
  
    <div class="tabs">
      <mat-tab-group (selectedTabChange)="getVariants($event)" #tabGroup>
        <mat-tab *ngFor="let fuel of fuelType" label="{{fuel}}">
          <div *ngFor="let variant of VariantsList" class="variant">
            {{variant}}
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  