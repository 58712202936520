import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
// import { MatDialog } from '@angular/material/dialog';
// import {AspLoginComponent} from '../asp-login/asp-login.component';
import {AspMakeService} from '../asp-make/asp-make.service';
import {AspLoginService} from '../asp-login/asp-login.service';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';


@Component({
  selector: 'app-asp-make',
  templateUrl: './asp-make.component.html',
  styleUrls: ['./asp-make.component.scss']
})
export class AspMakeComponent implements OnInit, AfterViewInit {  
  loading: boolean = false;
  selectedMake: any;
  searchText:string = '';
  makeList:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;
  spareSelectedGarageName: any;
@ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
@ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
 
constructor(
    private router: Router,
    // public dialog: MatDialog,  
    private aspMakeService: AspMakeService, 
    private aspLoginService: AspLoginService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    // this.aspLoginService.checkLogin();
    this.clearLocalStorage();    
    if(localStorage.getItem('selectedMake')){
      this.selectedMake = localStorage.getItem('selectedMake');
    }
    
    this.getMakeList();

  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }


  // Get Make List   
  getMakeList(){  
    let data = sessionStorage.getItem('spareVehicleDetails'); 
    let vehicleDetails;

    if(data){
      this.loading = false;
      vehicleDetails = data;
      let res = JSON.parse(vehicleDetails); 
      Object.values(res.data).forEach(make => {
        if(make[0]['title']=='Universal'){
          this.makeList.push(make[0]);
        }
      });

      Object.values(res.data).forEach(make => {
        if(make[0]['title']!='Universal'){
          this.makeList.push(make[0]);
        }
      });

    }else{
      this.aspMakeService.getMake().subscribe(
        result => {
          this.loading = false;
          // console.log(result);
          vehicleDetails = JSON.stringify(result);
          this.setVehicleDetails(vehicleDetails);
          let res = JSON.parse(vehicleDetails); 
          Object.values(res.data).forEach(make => {
            if(make[0]['title']=='Universal'){
              this.makeList.push(make[0]);
            }
          });

          Object.values(res.data).forEach(make => {
            if(make[0]['title']!='Universal'){
              this.makeList.push(make[0]);
            }
          });
        }
      );
    }
  }

  selectMake(make: any) {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newMake', make); 
    else
      localStorage.setItem('selectedMake', make);  

    this.router.navigate(['model']);

  }

  selectUniversalVehicle() {
    localStorage.setItem('vehicleType', 'universal'); 
    this.router.navigate(['spare-parts-list']);

  }

  setVehicleDetails(data){
    sessionStorage.setItem('spareVehicleDetails', data); 
  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  private clearLocalStorage(){
    sessionStorage.removeItem('sparePartsDetails');
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('isShippingCharges');
    localStorage.removeItem('prepayUpiDiscount');
    localStorage.removeItem('vehicleData');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('selectedFuel');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');
    // sessionStorage.removeItem('addedCart');
    // (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = '0';
    
  };

}
