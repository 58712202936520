import { Component, OnInit, Renderer2, ChangeDetectorRef, NgZone,AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AspProductDetailsService } from '../../auto-spare-parts/asp-product-details/asp-product-details.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AspPrductCompatibilityPopupComponent } from '../asp-prduct-compatibility-popup/asp-prduct-compatibility-popup.component';
import { ToastrService } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import { AspPlannedInventoryPopupComponent } from '../asp-planned-inventory-popup/asp-planned-inventory-popup.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AspPrductBottomsheetComponent } from '../asp-prduct-bottomsheet/asp-prduct-bottomsheet.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AspCartService } from 'app/main/auto-spare-parts/asp-cart/asp-cart.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';




@Component({
  selector: 'app-asp-product-details',
  templateUrl: './asp-product-details.component.html',
  styleUrls: ['./asp-product-details.component.scss'],
  // standalone: true,
  // imports: [MatTabsModule],
})



export class AspProductDetailsComponent implements OnInit,AfterViewInit {
  id: string;
  activeTab: string = 'data.description';
  type: string;
  lotsOfTabs = new Array(30).fill(0).map((_, index) => `Tab ${index}`);

  whenIsItNeeded: any = '';
  key: string = '';
  isShowOnce: number = 0;


  offer:any='';
  faqs: any[] = [];
  TopQuality:any='';
  addedCart:any=[];
  newData:any={};
  items: any[] = []; 
  counts: number = 0;
  data: any='';
  vehicleType:any = localStorage.getItem('vehicleType');
  cartCount = 0;
  cartSubTotal = 0;
  cartGrandTotal = 0; 
  cartList = [];
  isCartCount:boolean = false;
  vehicle: any;

  selectedModel: string = '';
  selectedYear: number;
  variants: any = {};
  getSelectedVariants: any[] = [];
  fuelList: number[] = [];
  getSelectedYears: any[] = [];
  VariantsList: string[] = [];
  fuelType: string[] = [];
  getSelectedTitles: string[] = [];
  getSelectedFuel: any;
  selectedCompatibleYear: number;
  compatability:any[] = [];
  showAllMap: { [key: string]: boolean } = {};
  isCompatibilityVisible: boolean = false;
  activeVehicle: any = null;
  discountType: string;

  @ViewChild('addButton',{ static: false }) addButton: ElementRef;
  // @ViewChild('compatibilitySection') compatibilitySection!: ElementRef;
  @ViewChild('compatabilityDiv') compatabilityDiv: ElementRef;
  // @ViewChild('recomanded', { static: false }) recomanded!: ElementRef;

  @ViewChild('secondDiv', { static: false }) secondDiv!: ElementRef;
  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;
  // @ViewChild('compatabilityDiv', { static: false }) compatabilityDiv: ElementRef;

  

  showBottomDiv = false;
  showCartDiv: boolean= false;
  topAddToCartButton: any;
  plannedSku: void;
  hasOpened: boolean = false;
  dataplannedSku: any;
  previouslyBought: any;
  orderedCount: any;
  garageCount: any;
  vehicles: any[] = [];
  loading: boolean = true;
  mrp: any;
  mrps: any;
  selling: any;
  minMrp: number;
  isMobile: boolean;
  shippingChargesApplicable: any;
  shippingCharges: any;
  avoidShippingCharges: any;
  grandAmount: any;
  sparesDiscount: number;
  

  selectTab(tab: string): void {
    this.activeTab = tab;
  }

  constructor
  (
    private aspProductDetailsService: AspProductDetailsService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private zone: NgZone,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private aspCartService: AspCartService,
    private router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }
  
  

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type');
      this.id = params.get('id');
    });

    this.getProductDetails();
    this.getCart();
    // Detect route changes
    // this.router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   console.log('Current route:', event.url);

    
    // (document.getElementsByClassName('mat-icon notranslate toolbar-hamburger-menu material-icons mat-icon-no-color')[0] as HTMLInputElement).innerHTML = '';
    // (document.getElementsByClassName('material-icons-arrowback-new')[0] as HTMLInputElement).innerHTML = 'arrow_back';
    // (document.getElementsByClassName('mat-icon notranslate garage-icon-mobile-view material-icons mat-icon-no-color')[0] as HTMLInputElement).innerHTML = '';
    // (document.getElementsByClassName('mywalletbtn')[0] as HTMLInputElement).innerHTML = '';
    // (document.getElementsByClassName('logo-icon')[0] as HTMLInputElement).innerHTML = '';

    // });
   
  }
  

  // public initialize() {
  //   this.getCart();
  // }

  ngAfterViewInit() {
    if (!this.compatabilityDiv) {
      console.error('secondDiv was not initialized.');
    }
    
  }
 

  // checkButtonPosition() {
  //   const rect = this.addButton.nativeElement.getBoundingClientRect();
  //   this.showCartDiv = rect.top <= 0;
  //    
  // }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   this.checkButtonPosition();
  // }

  // checkButtonHeight() {
  //   const buttonHeight = this.topAddToCartButton.nativeElement.getBoundingClientRect().top;
  //   console.log('Button Height:', buttonHeight); 
  //   this.showCartDiv = buttonHeight < 0;
  //   console.log('Show Cart Div:', this.showCartDiv); 
  // }

 
  getCompatability(partNumber: string) {
    this.loading=true;
    this.aspProductDetailsService.getProductCompatability(partNumber).subscribe(result => {
      let res = JSON.parse(JSON.stringify(result));
      console.log(res)
      this.loading=false;
      this.compatability = [];  // Initialize as an empty array
      res['data']?.vehicle?.forEach(vehicle => {
        let data = [];
        

        vehicle.models.forEach(model => {
          let rowKey = model.rowKey;
          let modelName = model.model;
          res['data']['variants'][rowKey]?.forEach(list => {
            list.model = modelName;
            data.push(list);
          });
        });
        this.compatability.push([vehicle.name, data]);

        // Initialize showAllMap for each vehicle
        this.showAllMap[vehicle.name] = false;
        this.vehicles=vehicle.models.length;
      });
      

      // console.log("compatability data: ",this.compatability);
    });
  }

  getVisibleModels(models: any[], vehicleName: string): any[] {
    return this.showAllMap[vehicleName] ? models : models.slice(0, 10);
  }

  toggleShowMore(vehicleName: string) {
    this.showAllMap[vehicleName] = !this.showAllMap[vehicleName];
  }
  toggleCompatibility(): void {
    this.isCompatibilityVisible = true;
  }

  scrollToCompatibility() {
    const offsetTop = this.compatabilityDiv.nativeElement.offsetTop;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });  
  }
  // scrollToRecomanded() {
  //   this.recomanded.nativeElement.scrollIntoView({
  //     behavior: 'smooth',  
  //     block: 'start'});
    
  // }
  // Method to set the active vehicle for the compatibility tab
  setActiveVehicle(vehicle: any): void {
    this.activeVehicle = vehicle;
  }

 

  getAllCatPopup = () => {
    this.zone.run(() => {
      const dialogRef =this.dialog.open(AspPrductCompatibilityPopupComponent);
      dialogRef.afterOpened().subscribe(() => {
        const componentInstance = dialogRef.componentInstance;
        // componentInstance.getProductCompatability(this.partNumber);
      });
    });
  }
  getProductDetails() {
    let garageKey = localStorage.getItem('garageKey');
    this.aspProductDetailsService.productDetails(garageKey, this.type,this.id).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code === 200){ 
          this.data = res.data;          
          this.whenIsItNeeded = JSON.parse(this.data.whenIsItNeeded);
          this.dataplannedSku=res.data.plannedSku;
          const sellingPrices = [];
          this.mrps = res?.data?.alternate?.forEach(alternates => {
            this.selling = alternates.sellingPrice;
            console.log("this.selling", this.selling);
            sellingPrices.push(this.selling);
          });
          this.minMrp = Math.min(...sellingPrices);
          console.log("this.minMrp", this.minMrp);

        const itemSummary = res?.data?.itemSummary && res?.data?.itemSummary?.length > 0 ? res?.data?.itemSummary[0] : null;
        
          this.previouslyBought = itemSummary?.previouslyBought;
          this.orderedCount = itemSummary?.orderedCount;
          this.garageCount = itemSummary?.garageCount;
  
          if (this.data.mrp > 0) {
            this.discountType = ((this.data.discount / this.data.mrp) * 100).toFixed(0) + '%';
          } 
          
          this.getCompatability(res.data.partNumber);
        } else {
          // this.loading = false;
          this.toastr.error(result['data']['description'], 'Item Details');
        }
      }
    );
  }

  addCart(count){
    // this.scrollToRecomanded();
    let preferredWarehouse: any = '';
    let availableStock: number = 0;
    // if (this.data?.alternate?.length !==0 && this.data?.alternate !==null && !this.hasOpened && this.loading==false) {
    //   this.hasOpened = true;  // Mark as opened
    //   const bottomSheetRef = this.bottomSheet.open(AspPrductBottomsheetComponent, {
    //     data: { id: this.data.partNumber},

    //     panelClass: 'custom-bottom'
    //   });
  
    //   // Reset the flag when the bottom sheet is dismissed
    //   bottomSheetRef.afterDismissed().subscribe(() => {
    //     this.hasOpened = false;  // Reset flag after closing
    //   });
    // }
    // else 
    // if(this.data?.alternate?.length >= 0 || this.data?.alternate ==null || this.hasOpened) {
      // if(this.data.plannedSku==1){
        // this.data.warehouseData.forEach(warehouse => {
        //   if(warehouse.preferred==true){
        //     preferredWarehouse = warehouse.name;
        //   }
        // });

        // if(preferredWarehouse){
        //   this.data.warehouseStock.forEach(stock => {
        //     if(stock.name.toLowerCase().includes(preferredWarehouse.toLowerCase())){
        //       availableStock = stock.stock;
        //     }
        //   });

        //   if(count > availableStock){
        //     this.openPlannedSkuPopup(availableStock, count, this.data.plannedSku);
        //   }else{
        //     this.addItemToCart(count);
        //   }
        // }      
      // }
      // else{
        // if(count > availableStock && this.isShowOnce==0){
        //   this.isShowOnce = 1;
        //   // this.openPlannedSkuPopup(availableStock, count, this.data.plannedSku);
        // }
        // else{
          this.addItemToCart(count);
        // }
      // }
    // }
  
    // this.addItemToCart(count);
  }

  addItemToCart(count){      
    this.addedCart = [];
    if(this.vehicleType=='universal'){
      this.data.type = 'universal';
    }
    if(sessionStorage.getItem('addedCart')){
      this.newData = JSON.parse(sessionStorage.getItem('addedCart'))[0];
      this.data.cart_qty = count;
      
      delete this.data.rowKey;
      delete this.data.variantKey;
      let make = localStorage.getItem('selectedMake');
      let model = localStorage.getItem('selectedModel');
      let registrationNumber = localStorage.getItem('registrationNumber');
      let variant = localStorage.getItem('variant');
      let mfgYear = localStorage.getItem('selectedYear');
      let fuelType = localStorage.getItem('selectedFuel');
      let rowKey = localStorage.getItem('rowKey');
      let variantKey = localStorage.getItem('variantKey');
      this.data = {
        ... this.data, 
        selectedMake: make,
        selectedModel: model,
        registrationNumber: registrationNumber,
        variant: variant,
        selectedYear: mfgYear,
        selectedFuel: fuelType, 
        rowKey: rowKey, 
        variantKey: variantKey      
      };
      this.newData[this.data.key] = this.data;
      this.addedCart.push(this.newData);
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    }else{
      this.data.cart_qty = count;
      delete this.data.rowKey;
      delete this.data.variantKey;
      let make = localStorage.getItem('selectedMake');
      let model = localStorage.getItem('selectedModel');
      let registrationNumber = localStorage.getItem('registrationNumber');
      let variant = localStorage.getItem('variant');
      let mfgYear = localStorage.getItem('selectedYear');
      let fuelType = localStorage.getItem('selectedFuel');
      let rowKey = localStorage.getItem('rowKey');
      let variantKey = localStorage.getItem('variantKey');
      this.data = {
        ... this.data, 
        selectedMake: make,
        selectedModel: model,
        registrationNumber: registrationNumber,
        variant: variant,
        selectedYear: mfgYear,
        selectedFuel: fuelType, 
        rowKey: rowKey, 
        variantKey: variantKey      
      };
      this.newData[this.data.key] = this.data;
      this.addedCart.push(this.newData);
    //  console.log(this.addedCart);
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    }      
    this.getCart();
  }


  openPlannedSkuPopup(availableStock, count, plannedSku){
    const dialogRef = this.dialog.open(AspPlannedInventoryPopupComponent, {
      "data":{
        "itemDetails": this.data,
        "plannedSku": plannedSku,
        "availableStock": availableStock,
        "count": count
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProductDetails();
      this.getCart();      
    });
  }

  
  getCart(){
    let cartData;  
    this.cartList = [];
    this.cartCount = 0;
    this.cartSubTotal = 0;
    this.cartGrandTotal = 0;
     if(sessionStorage.getItem('addedCart')){   
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      if(Object.keys(cartData[0]).length==0){
        this.isCartCount = false;
      } 

      this.cartCount = 0;      
      Object.values(cartData[0]).forEach(cart => { 
        if(cart){
          this.isCartCount = true;

          this.cartCount++;

     // this.cartCount += cart['cart_qty'];
        if(cart['sellingPrice']>0){
          this.cartSubTotal += (cart['sellingPrice']*cart['cart_qty']);
        }else{
          this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        }
        this.cartList.push(cart);

      }
      });   

      this.cartList = cartData;
      this.cartGrandTotal = this.cartSubTotal;
      // console.log(this.cartList);
      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
    }else{
        this.cartGrandTotal = this.cartSubTotal;
    }
    this.getShippingCharges()
  
    }
  }
  goToCart(){
    this.router.navigate(['cart']);
  }

  removeItem(count, item){ 
    this.addedCart = [];    
    let data;
    
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      data[item].cart_qty = countval;

      if(this.vehicleType=='universal'){
        data[item].type = 'universal';
      }

      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      if(this.isShowOnce = 1){
        this.isShowOnce = 0;
      }
      this.deleteItem(item);
      this.toastr.error(data[item].name+' removed from cart', 'Cart Item');
    }
  }

  deleteItem(item){
    this.newData = {};
    let cartData;

    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['key']!=item) {
        this.newData[cart['key']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }

  addItem(count){
    let countval = count+1;
    this.addCart(countval);
    // this.addedCart = [];
    // let data;
    
    // data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
    //   data[item].cart_qty = countval;

    //   if(this.vehicleType=='universal'){
    //     data[item].type = 'universal';
    //   }

    //   this.addedCart[0] = data;
    //   sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    //   this.getCart();
    // }else{
    //   this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    // }
  }
  
  
  
  saveMore(){
    if (this.data?.alternate?.length !==0 && this.data?.alternate !==null) {
      this.hasOpened = true;
      const bottomSheetRef = this.bottomSheet.open(AspPrductBottomsheetComponent, {
        data: { id: this.data.partNumber},

        panelClass: 'custom-bottom'
      });
  
      // Reset the flag when the bottom sheet is dismissed
      bottomSheetRef.afterDismissed().subscribe(() => {
        this.hasOpened = false;  // Reset flag after closing
      });
    }
  }
  getShippingCharges(){    
    // this.loading = true; 
    let garageKey = localStorage.getItem('garageKey');
    this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          this.shippingChargesApplicable = res.data.shippingChargesApplicable;
          this.shippingCharges = res.data.shippingCharges;
          this.avoidShippingCharges = res.data.avoidShippingCharges;
          this.grandAmount= res.data.cartValue;
          this.loading = false;
            this.cartGrandTotal = this.grandAmount+this.shippingCharges;

            if(!this.isMobile){
              if(this.cartCount>0){
                (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
              }else{
                (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
              }
            }else{
              if(this.cartCount>0){
                (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
              }else{
                (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
              }
            }            
          
        } else {
          if(!this.isMobile){
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
            }
          }else{
            if(this.cartCount>0){
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
            }else{
              (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
            }
          }
        }
      }
    );
  }
  goBack(): void {
    this.location.back();
  }
  @HostListener('window:scroll', [])
  onScroll() {
    if (!this.secondDiv || !this.secondDiv.nativeElement) {
      console.warn('secondDiv not initialized yet.');
      return;
    }
  
    const rect = this.secondDiv.nativeElement.getBoundingClientRect();
    this.showBottomDiv = rect.top <= 0;
    console.log('secondDiv position:', rect.top);
    const container = document.querySelector('.scroll-container');
  container.scrollTop = container.scrollHeight;
  }
}




