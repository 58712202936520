import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import {AspYearService} from './asp-year.service'
import {AspLoginService} from '../asp-login/asp-login.service';
import { AspGarageSidebarComponent } from '../asp-garage-sidebar/asp-garage-sidebar.component';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';

@Component({
  selector: 'app-asp-year',
  templateUrl: './asp-year.component.html',
  styleUrls: ['./asp-year.component.scss']
})
export class AspYearComponent implements OnInit, AfterViewInit {
  loading:boolean = false;
  searchText:any = '';
  yearList:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;  
  selectedData = '';
  selectedMake: any;
  selectedModel: any;
  spareSelectedGarageName: any;

  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  constructor(
    private router: Router,
    private aspYearService: AspYearService,
    private aspLoginService: AspLoginService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) { }

 
  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.selectedModel = localStorage.getItem('newModel');
    else
      this.selectedModel = localStorage.getItem('selectedModel');
    
    this.getYearData();  

    if(localStorage.getItem('selectedYear')){
      this.selectedData = localStorage.getItem('selectedYear');
    }
    
    
  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  
  
  // Get Year List       
  getYearData(){
    let result = this.aspYearService.getYear();
    this.loading = false;
    let res = JSON.parse(result); 
    Object.values(res.data[this.selectedMake]).forEach(list => {
      if(list['model']===this.selectedModel){
       
        if(localStorage.getItem('rowKey'))
          localStorage.setItem('newRowKey', list['rowKey']);
        else
          localStorage.setItem('rowKey', list['rowKey']);

        let yearTo;
        if(!list['yearTo']){
          yearTo = new Date().getFullYear();
        }else{
          yearTo = list['yearTo'];
        }

        for(let i=list['yearFrom']; i<=yearTo; i++){
          this.yearList.push({'title':i.toString()});
        }
      }
    });
  }
  // End Get Year List
  
  selectYear(year: any) {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newYear', year); 
    else
      localStorage.setItem('selectedYear', year); 

    this.router.navigate(['variant']);

  }

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  goToMake(){      
    this.router.navigate(['make']);
  }

  goToModel(){      
    this.router.navigate(['model']);
  }
    
}