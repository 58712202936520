import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
// import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
// import { MatRadioModule } from '@angular/material/radio';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AspBrandsComponent } from './asp-brands.component';

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
      path: 'all-brands',
      component: AspBrandsComponent,
  },
];

@NgModule({
  declarations: [AspBrandsComponent],
  imports: [    
    RouterModule.forChild(routes),
    FuseSharedModule,
    CommonModule,
    MatCardModule,
    // MatDialogModule,
    MatButtonModule,
    // MatFormFieldModule,
    MatIconModule,
    // MatInputModule,
    // MatStepperModule,
    CdkStepperModule,
    MatProgressBarModule,
    // MatProgressSpinnerModule,
    ApplicationPipesModule,
    // MatRadioModule,
    // MatAutocompleteModule,
    HttpClientModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
    }
    })
  ],  
  exports: [AspBrandsComponent],
})
export class AspBrandsModule { }
