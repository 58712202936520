import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspSwitchGarageService {

  constructor(
    private http: HttpClient, 
  ) { }

  getUserDetails(spareUserKey){
    let paramData = {      
      userKey: spareUserKey,
      source: "web"
    };
    // console.log(paramData)
    console.log('-----Get User Details-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/getUserDetails', paramData);
  }
}
