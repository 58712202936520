import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspPaymentlistComponent } from './asp-paymentlist.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [
  {
      path: 'my-payments',
      component: AspPaymentlistComponent,
      
  },
];


@NgModule({
  declarations: [AspPaymentlistComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,BrowserModule,FormsModule,MatMenuModule,MatFormFieldModule,BrowserAnimationsModule
  ],
  exports:[AspPaymentlistComponent,RouterModule]
})
export class AspPaymentlistModule { }







// @NgModule({
//   declarations: [AspBookingComponent],
//   imports: [
//     RouterModule.forChild(routes),
//     CommonModule,BrowserModule
//   ],
//   bootstrap: [AspBookingComponent],
//   exports:[AspBookingComponent,RouterModule]
// })