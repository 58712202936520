import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AspGarageSidebarService } from './asp-garage-sidebar.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asp-garage-sidebar',
  templateUrl: './asp-garage-sidebar.component.html',
  styleUrls: ['./asp-garage-sidebar.component.scss']
})
export class AspGarageSidebarComponent implements OnInit {
  garageList:any = [];
  garageKey: any;
  isVisible: boolean;
  searchText:string = '';
  garagename: any;
  filteredGarage: any;
  selectedGarageKey:any=[];

  constructor(
    private aspGarageSidebarService:AspGarageSidebarService,
    private toastr: ToastrService, 
    private router: Router,
  ) { }

  ngOnInit(): void {         
    this.garageKey = localStorage.getItem("garageKey");
    this.getGaragesList();
  }

  closeSidebar(ishide: boolean) {
    this.isVisible = ishide;
    
  }
 
  getGaragesList(){    
    let spareUserKey = localStorage.getItem('spareUserKey');
    this.aspGarageSidebarService.getUserDetails(spareUserKey).subscribe(
      result => {
        console.log("gragedata",result);
        let res = JSON.parse(JSON.stringify(result)); 
        
        if(res.code && res.code==200){
          this.garageList = res.data.garage;
          this.filteredGarage = this.garageList.sort((a, b) => a.name.localeCompare(b.name));
        }else{
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }
  selectGarage(garageKey, garageName,contactEmail,contactPhone){
    sessionStorage.removeItem('addedCart');
    localStorage.setItem("garageKey", garageKey);
    localStorage.setItem("spareSelectedGarageName", garageName);
    localStorage.setItem("contactEmail", contactEmail);
    localStorage.setItem("contactPhone", contactPhone);
    this.router.navigate(['/home']).then(() => {
      window.location.reload();
     });
    this.toastr.success(garageName+' selected successfully', 'Garage selected');
    this.closeSidebar(false);
  }

  clearSearch() {
     this.searchText = ''; 
     this.searchFilterType();
   }
   searchFilterType = () =>{    
    this.filteredGarage = this.garageList.filter(garage =>
      garage.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
}
}
