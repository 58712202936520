import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import{ AspPrductBottomsheetService } from './asp-prduct-bottomsheet.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-asp-prduct-bottomsheet',
  templateUrl: './asp-prduct-bottomsheet.component.html',
  styleUrls: ['./asp-prduct-bottomsheet.component.scss']
})
export class AspPrductBottomsheetComponent implements OnInit {
  itemName: string;
  alternatives: any[] = [];
  hasScroll: boolean = false;
  isAtStart: boolean = true;  
  isAtEnd: boolean = false;

  @ViewChild('cardContainer', { static: false }) cardContainer: ElementRef;
  alternate: any;
  dataRes: any;
  itemSummary: any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AspPrductBottomsheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private aspPrductBottomsheetService:AspPrductBottomsheetService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.itemName = this.data.itemName;
    this.alternatives = this.data.alternatives;

    if (this.alternatives?.length > 1) {
      this.hasScroll = true;
    }
    this.getProductDetails();
  }

  closeSheet(): void {
    this.bottomSheetRef.dismiss();
  }

  

  scrollLeft(): void {
    if (this.cardContainer && this.cardContainer.nativeElement) {
      const container = this.cardContainer.nativeElement;
      container.scrollTo({
        left: container.scrollLeft - 275,
        behavior: 'smooth'
      });
    }
  }
  
  scrollRight(): void {
    if (this.cardContainer && this.cardContainer.nativeElement) {
      const container = this.cardContainer.nativeElement;
      container.scrollTo({
        left: container.scrollLeft + 275,
        behavior: 'smooth'
      });
       
    }
  }
  

  
  

  navigateToDetails(alternative: any): void {
    // Logic to navigate to the product details page
  }

  getProductDetails() {
    let garageKey = localStorage.getItem('garageKey');
    let partNumber = this.data.id;
    this.aspPrductBottomsheetService.productDetails(garageKey, partNumber).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        this.dataRes=res['data'];
        this.alternate=res.data['alternate'];
      }
    );
  }
  navigateToProductDetails(item: any): void {
    // Close the bottom sheet
    this.bottomSheetRef.dismiss();
    
    // Navigate to the product details page
    this.router.navigate(['/product-details/key/', item.key]).then(() => {
      window.location.reload();
  });
  }
}
