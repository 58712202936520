import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspProductDetailsComponent } from './asp-product-details.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationPipesModule } from 'app/main/applicationPipesModule.module';
import {MatTabsModule} from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const routes: Routes = [
  {
      path: 'product-details/:type/:id',
      component: AspProductDetailsComponent,
  },
];

@NgModule({
  declarations: [AspProductDetailsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ApplicationPipesModule,
    MatTabsModule
    
  ],
  
  exports: [AspProductDetailsComponent],
})
export class AspProductDetailsModule { }
