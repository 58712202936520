import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspSparesListService {

  constructor(
    private http: HttpClient,
  ) { }

  getSparesList(rowKey='', variantKey='', searchKey='', garageKey, pageIndex, pageSize, selectedCategory, brandKey, plannedSku,sortingField = 'sold_quantity', sortingOption = 'des') {  
    console.log("calling api with plannedsku",plannedSku )
    let selectedSparesList = {};
    let API_ACTION;
    let paramData;  
    let params;

    if(plannedSku==true){
      plannedSku = 1;
    }else{
      plannedSku = 0;
    }

    if(localStorage.getItem('selectedSparesList'))
        selectedSparesList = JSON.parse(localStorage.getItem('selectedSparesList'));

    let subCategories = [];
    if(selectedCategory[selectedSparesList['name']] && selectedCategory[selectedSparesList['name']]!='' && selectedCategory[selectedSparesList['name']]!=undefined)
        subCategories = selectedCategory[selectedSparesList['name']]

    if(selectedSparesList && selectedSparesList['type'] == 'categorySpares') {
        API_ACTION = '/spare/v2/getCategorySpares';
        paramData = {
            "brandKeys": brandKey,
            "category": selectedSparesList['name'],
            "garageKey": garageKey,
            "rowKey": rowKey,    
            "variantKey": variantKey,
            "searchKey": searchKey,
            "sortingField": sortingField,
            "sortingOption": sortingOption,
            "subCategories": subCategories,
            "universal": false,
            "source": "web",
            "plannedSku": plannedSku
        }

        params = new HttpParams()
            .set('page', pageIndex.toString()) 
            .set('size', pageSize.toString());

    } else if (selectedSparesList && selectedSparesList['type'] == 'brandSpares') {
        let formattedArray = [];

        Object.entries(selectedCategory).forEach(([categoryName, subCategoryName]) => {
            formattedArray.push({ categoryName: categoryName, subCategoryName: subCategoryName });
        });

        API_ACTION = '/spare/getBrandSpares';
        paramData = {
            "brand": selectedSparesList['name'],
            "categoryFilter": formattedArray,
            "garageKey": garageKey,
            "rowKey": rowKey,
            "searchKey": searchKey,
            "sortingField": sortingField,
            "sortingOption": sortingOption,
            // "sortingOption": "",
            "universal": false,
            "variantKey": variantKey,
            "source": "web", 
            "plannedSku": plannedSku   
        }

        params = new HttpParams()
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString());

    } else {
        API_ACTION = '/spare/getAll';
        paramData = {      
            rowKey: rowKey,
            variantKey: variantKey,
            searchKey: searchKey,
            garageKey: garageKey,
            source: "web",
            plannedSku: plannedSku,
            "sortingField": sortingField,
            "sortingOption": sortingOption,
        };

        params = new HttpParams()
        .set('page', pageIndex.toString()) 
        .set('size',  pageSize.toString());
    }

    console.log('-----Get Spares List-----------');    

    if(API_ACTION)
        return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + API_ACTION, paramData, { params });
}



  getBrands(categories, rowKey, variantKey){

    let selectedSparesList = {};
    if(localStorage.getItem('selectedSparesList'))
      selectedSparesList = JSON.parse(localStorage.getItem('selectedSparesList'));

    if(Object.keys(categories).length==0 && Object.keys(selectedSparesList).length>0 && selectedSparesList['type']=='categorySpares'){
      if(selectedSparesList && selectedSparesList['type']=='categorySpares'){
        const name = selectedSparesList['name'];
        categories = [{[name]: []}];
      }      
    }else{
      if(Object.keys(categories).length>0){
        categories = [categories];
      }else{
        categories = [];
      }
    }
  

    let paramData ={
      categories:categories,
      rowKey : rowKey,
      variantKey : variantKey
    }
    console.log(paramData);
    
    console.log('-----Brands for filter-----------');   
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/filter/get/brands',paramData)
  }

  getCategories(brandKeys, rowKey, variantKey ){
    let paramData ={
      brandKeys: brandKeys,
      rowKey : rowKey,
      variantKey : variantKey
    }
    // console.log(paramData);
    console.log('-----Categories for filter-----------');  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/filter/get/categories',paramData);
  }
  getLoadingMsg(){
    let paramData ={
    source:"",
    }
    // console.log(paramData);
    console.log('-----get loading msg-----------');  
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` +'/app/getAppData',paramData);
  }

}

