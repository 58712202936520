import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMyRequestService {

  constructor(
    private http: HttpClient,
  ) { }

  myRequest(userKey, garageKey){
    let paramData = {      
      userKey: userKey,
      garageKey: garageKey,
      source: "web" 
    };

    console.log('-----Get My Request-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/request/all', paramData);
  }
}
