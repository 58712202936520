import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-details-pop-up',
  templateUrl: './asp-request-details-pop-up.component.html',
  styleUrls: ['./asp-request-details-pop-up.component.scss'],
  providers: [ DatePipe ]
})

export class AspRequestDetailsPopUpComponent implements OnInit {
  successIcon: any;
  spareRequestItemList: any;
  requestDetails: any;
  approvedAt: any;
  // @ViewChild(AspRequestDetailsComponent) requestDetailsComponent!: AspRequestDetailsComponent;


  constructor(
    public dialog: MatDialogRef <AspRequestDetailsPopUpComponent >,
    @Inject(MAT_DIALOG_DATA) public data: any, // Inject MAT_DIALOG_DATA here
    private date: DatePipe
  ) { 
    dialog.disableClose = true;
    
  }

  ngOnInit(): void {
    this.successIcon = '../../../../assets/icons/icons8-ok.gif';
    this.approvedAt = this.date.transform(this.data.approvedAt, 'MMM dd yyyy hh:mm a','GMT+1060' );
  }

  closePopup() {
    this.dialog.close();
    }

  goToQuoteApproved() {
    // this.requestDetailsComponent.setActiveTab('quote-approved');
    this.dialog.close('quote-approved');
  }  
}
