import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../cat-all/cat-all-popup/cat-all-popup.component';
import { CatAllService } from './cat-all.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cat-all',
  templateUrl: './cat-all.component.html',
  styleUrls: ['./cat-all.component.scss']
})
export class CatAllComponent implements AfterViewInit {  

  
  @ViewChild('catalogImg', { static: false }) catalogImg: ElementRef;
  @ViewChild('compatabilityDiv') compatabilityDiv: ElementRef;

  loading: boolean = false;
  carList: any = [];
  selectedCar: number = 4;  
  res: any;
  selectedPage: number | null = null;

  constructor(
    private catAllService: CatAllService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) { }

  pageNumber: number =1;
  maker: any;
  model: any;
  year: any;
  variantId: any;
  
  // title: any = `${this.maker} ${this.model} ${this.variantId}`;
  
  croppedImageURL: any;
  pageTitle: any;
  carDetails: any = [];
  highlightAreas: any = [];
  activeId: any = '';

  isEditMode: boolean = false;
  startX: number = 0;
  startY: number = 0;
  endX: number = 0;
  endY: number = 0;  
  treeData: any = [];
  activeMenu: any = null;
  activeSubMenu: any = null;
  tLeft: any = '';
  tRight: any = '';
  bLeft: any = '';
  bRight: any = '';
  brushes = [
    { name: 'smallBrush', label: 'Small', icon: 'brush' },
    // { name: 'mediumBrush', label: 'Medium', icon: 'brush' },
    { name: 'largeBrush', label: 'Large', icon: 'brush' },
  ];
  selectedBrush = 'smallBrush'; // Default selected brush

  
  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  selectBrush(brush: any): void {
    this.selectedBrush = brush.name;
    console.log(`Selected brush: ${brush.label}`);
    // Here you can add functionality to apply the brush size
  }

  ngOnInit(): void {
    // Retrieve the page number from session storage
    const storedPageNumber = localStorage.getItem('pageNumber');
    this.pageNumber = storedPageNumber ? +storedPageNumber : 1;
  }
  
  ngAfterViewInit() {
    this.loading = true; 
    this.getCarsList();  
    // this.catalogData();
    this.attachImageEventListeners();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateMarkerPositions();
  }

  catalogData() {   
    this.carDetails = [];
    this.highlightAreas = [];
    this.activeId = '';
    
    this.catAllService.getCatalogDetails(this.pageNumber, this.maker, this.model, this.year, this.variantId).subscribe(
      res => {
        console.log(res)
        this.loading = false;          
        let response = res;
        this.croppedImageURL = response[0].croppedImageURL;
        this.pageTitle = response[0].pageTitle;
        this.carDetails = response[0].carDetails;
        //do loop call id        
        Object.values(this.carDetails).forEach(list => {
          let refNoCoordinates = JSON.parse(list['refNoCoordinates']);
          console.log("refNoCoordinates",refNoCoordinates)
          let x = 0;
          let y = 0;
          let x1 = 0;
          let y1 = 0;
          if (refNoCoordinates && refNoCoordinates.length > 0) {
            x = refNoCoordinates[0].x;
            y = refNoCoordinates[0].y;
            x1 = refNoCoordinates[2].x;
            y1 = refNoCoordinates[2].y;
          }

          if (x !== 0 && y !== 0) {
            this.highlightAreas.push({ refNo: list['refNo'], x: x, y: y , x1: x1, y1: y1 });
            console.log(x,y,x1,y1);
          }
        });
        this.updateMarkerPositions();
      }
    );
  }

  updateMarkerPositions() {
    if (this.catalogImg && this.catalogImg.nativeElement && this.catalogImg.nativeElement.complete) {
      const img = this.catalogImg.nativeElement;
      const imgWidth = img.clientWidth;
      const imgHeight = img.clientHeight;
      const originalWidth = img.naturalWidth;
      const originalHeight = img.naturalHeight;

      this.highlightAreas.forEach(area => {
        area.newX = (area.x / originalWidth) * imgWidth;
        area.newY = (area.y / originalHeight) * imgHeight;
        area.newX1 = (area.x1 / originalWidth) * imgWidth;
        area.newY1 = (area.y1 / originalHeight) * imgHeight;
        console.log(area.newX,area.newY,area.newX1,area.newY1);
      });
      this.cd.detectChanges();
    }
  }

  attachImageEventListeners() { 
    const imgElem = this.catalogImg.nativeElement;
    const coordinatesDisplay = document.getElementById('coordinates');
    let im2Elem: HTMLDivElement;

    if (this.selectedBrush === 'smallBrush') {
        im2Elem = document.querySelector('.im2') as HTMLDivElement;
    // } else if (this.selectedBrush === 'mediumBrush') {
    //     im2Elem = document.querySelector('.im2Medium') as HTMLDivElement;
    } else if (this.selectedBrush === 'largeBrush') {
        im2Elem = document.querySelector('.im2large') as HTMLDivElement;
    } else {
        console.error('Invalid brush selected:', this.selectedBrush);
        return; // Exit if selectedBrush doesn't match any case
    }
    const editModeToggle = document.getElementById('editModeToggle') as HTMLButtonElement;

    if (editModeToggle) {
      editModeToggle.addEventListener('click', () => {
        this.isEditMode = !this.isEditMode;
        const editButton = document.getElementById('editModeToggle') as HTMLButtonElement;
        if (editButton) {
          editButton.textContent = this.isEditMode ? 'Editing...' : '✏ Edit';
        }
      });
    }

    imgElem.addEventListener('mousedown', this.onMouseDown);    
    this.cd.detectChanges();
  }
  onMouseDown = (e: MouseEvent) => {
    const imgElem = this.catalogImg.nativeElement;
    let im2Elem: HTMLDivElement;

    // Determine element and dimensions based on selected brush
    let size: number;
    if (this.selectedBrush === 'smallBrush') {
        im2Elem = document.querySelector('.im2') as HTMLDivElement;
        size = 10; // 10px square for small brush
    } else if (this.selectedBrush === 'largeBrush') {
        im2Elem = document.querySelector('.im2large') as HTMLDivElement;
        size = 20; // 20px square for large brush
    } else {
        console.error('Invalid brush selected:', this.selectedBrush);
        return; // Exit if selectedBrush doesn't match any case
    }

    if (!this.isEditMode) return;

    // Prevent triggering on both highlight areas and the image if needed
    e.stopPropagation();

    // Get mouse coordinates relative to image
    const rect = imgElem.getBoundingClientRect();
    this.startX = e.clientX - rect.left;
    this.startY = e.clientY - rect.top;

    // Set styles for single point at mouse position
    im2Elem.style.width = `${size}px`;
    im2Elem.style.height = `${size}px`;
    im2Elem.style.left = `${this.startX - size / 2}px`; // Center the box
    im2Elem.style.top = `${this.startY - size / 2}px`;
    im2Elem.style.display = 'block';

    // Calculate scaled coordinates
    const imgWidth = imgElem.clientWidth;
    const imgHeight = imgElem.clientHeight;
    const originalWidth = imgElem.naturalWidth;
    const originalHeight = imgElem.naturalHeight;
    const scaleX = originalWidth / imgWidth;
    const scaleY = originalHeight / imgHeight;

    // Define each corner of the box around the click point
    this.tLeft = {
        x: (this.startX - size / 2) * scaleX, // Top-left X
        y: (this.startY - size / 2) * scaleY  // Top-left Y
    };
    this.tRight = {
        x: (this.startX + size / 2) * scaleX, // Top-right X
        y: (this.startY - size / 2) * scaleY  // Top-right Y
    };
    this.bLeft = {
        x: (this.startX - size / 2) * scaleX, // Bottom-left X
        y: (this.startY + size / 2) * scaleY  // Bottom-left Y
    };
    this.bRight = {
        x: (this.startX + size / 2) * scaleX, // Bottom-right X
        y: (this.startY + size / 2) * scaleY  // Bottom-right Y
    };

    console.log('Top-left:', this.tLeft);
    console.log('Top-right:', this.tRight);
    console.log('Bottom-left:', this.bLeft);
    console.log('Bottom-right:', this.bRight);

    this.openConfirmationPopup();
};

 
//  onMouseDown = (e) => {
//     const imgElem = this.catalogImg.nativeElement;
//     let im2Elem: HTMLDivElement;

//     // Determine element and dimensions based on selected brush
//     let size: number;
//     if (this.selectedBrush === 'smallBrush') {
//         im2Elem = document.querySelector('.im2') as HTMLDivElement;
//         size = 10; // 10px square for small brush
//     } else if (this.selectedBrush === 'largeBrush') {
//         im2Elem = document.querySelector('.im2large') as HTMLDivElement;
//         size = 20; // 20px square for large brush
//     } else {
//         console.error('Invalid brush selected:', this.selectedBrush);
//         return; // Exit if selectedBrush doesn't match any case
//     }

//     if (!this.isEditMode) return;

//     // Get mouse coordinates relative to image
//     const rect = imgElem.getBoundingClientRect();
//     this.startX = e.clientX - rect.left;
//     this.startY = e.clientY - rect.top;

//     // Set styles for single point at mouse position
//     im2Elem.style.width = `${size}px`;
//     im2Elem.style.height = `${size}px`;
//     im2Elem.style.left = `${this.startX - size / 2}px`; // Center the box
//     im2Elem.style.top = `${this.startY - size / 2}px`;
//     im2Elem.style.display = 'block';

//     // Calculate scaled coordinates
//     const imgWidth = imgElem.clientWidth;
//     const imgHeight = imgElem.clientHeight;
//     const originalWidth = imgElem.naturalWidth;
//     const originalHeight = imgElem.naturalHeight;
//     const scaleX = originalWidth / imgWidth;
//     const scaleY = originalHeight / imgHeight;

//     // Define each corner of the box around the click point
//     this.tLeft = {
//         x: (this.startX - size / 2) * scaleX, // Top-left X
//         y: (this.startY - size / 2) * scaleY  // Top-left Y
//     };
//     this.tRight = {
//         x: (this.startX + size / 2) * scaleX, // Top-right X
//         y: (this.startY - size / 2) * scaleY  // Top-right Y
//     };
//     this.bLeft = {
//         x: (this.startX - size / 2) * scaleX, // Bottom-left X
//         y: (this.startY + size / 2) * scaleY  // Bottom-left Y
//     };
//     this.bRight = {
//         x: (this.startX + size / 2) * scaleX, // Bottom-right X
//         y: (this.startY + size / 2) * scaleY  // Bottom-right Y
//     };

//     console.log('Top-left:', this.tLeft);
//     console.log('Top-right:', this.tRight);
//     console.log('Bottom-left:', this.bLeft);
//     console.log('Bottom-right:', this.bRight);

//     this.openConfirmationPopup();
// };



  openConfirmationPopup() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '250px',
      data: {
        showInput: true,
        topLeft: this.tLeft,
        topRight: this.tRight,
        bottomLeft: this.bLeft,
        bottomRight: this.bRight,
        pageNumber: this.pageNumber,
        maker: this.maker,
        model: this.model,
        year: this.year,
        variantId: this.variantId
        // coordinates: coordinatesMessage
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed) {
        console.log('Confirmed with ID:', result.id);
        // console.log('Coordinates:',);
        // console.log('ID:', result.id);
        // Handle saving logic here
        // For example, save coordinates and ID to a server or local storage
      } else {
        console.log('Operation canceled');
      }
    });
  }
  

  showData(refNo) {
    this.activeId = refNo;
   
      let selectedRow = this.el.nativeElement.querySelector(`#item-${refNo}`);
      if (selectedRow) {
        selectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
      });
      }
      this.cd.detectChanges();
    // this.compatabilityDiv.nativeElement.scrollIntoView({ });
  }
  @HostListener('window:scroll', []) 


  pageSearch() {
    this.pageNumber = parseInt(this.el.nativeElement.querySelector('#pageNumber').value);
    this.ngAfterViewInit();
    localStorage.setItem('pageNumber', this.pageNumber.toString());
  }

  paginationPage(type, pageNo=0, subMenu: any='') {
    this.loading = true;
    
    setTimeout(() => {
      if (type === 'prev') {
        this.pageNumber--;
      } else if (type === 'next') {
        this.pageNumber++;
      }else if(type='treePage'){
        this.pageNumber = pageNo;
      }
      
    localStorage.setItem('pageNumber', this.pageNumber.toString());
      this.catalogData();
      this.loading = false;
    }, 500);
      this.activeSubMenu = this.activeSubMenu === subMenu ? null : subMenu;  
      
      this.selectedPage=pageNo;
      
    console.log("this.pageNumber",this.pageNumber)
  }

  toggleMenu(category: any) {
    this.activeMenu = this.activeMenu === category ? null : category;
  }

  toggleMenuClose(category: any) {
    this.activeMenu = null;
    this.activeSubMenu = null;
  }
  
  toggleSubMenu(subMenu: any) {
    this.activeSubMenu = this.activeSubMenu === subMenu ? null : subMenu;  
  }

  getCarsList(){
    this.loading = true;  
    this.catAllService.getCarsList().subscribe(
      res => {
        this.loading = false; 
        this.carList = res;
        this.getSelectedCarData();
      }
    );
  }

  onSelectChangeCar(event){
    this.getSelectedCarData();
  }

  getSelectedCarData(){
    this.pageNumber = this.pageNumber;
    this.maker = '';
    this.model = '';
    this.year = '';
    this.variantId = '';
  
    this.croppedImageURL = '';
    this.pageTitle = '';
    this.carDetails = [];
    this.highlightAreas = [];
    this.activeId = '';
    this.treeData = [];

    if(this.selectedCar){
      this.carList.forEach(cars => {
        if(cars.id == this.selectedCar){
          this.maker = cars.maker;
          this.model = cars.model;
          this.year = cars.manufacturingYear;
          this.variantId = cars.variantId;
        }
      });
    }
    this.getTreeData();
    this.catalogData();
  }

  getTreeData() {
    this.loading = true;
    this.catAllService.getTreeData(this.selectedCar).subscribe(
      result => {
        this.loading = false; 
        console.log("res",result)
        this.res = JSON.parse(JSON.stringify(result));
         
        
        // Object.entries(res).forEach(([key, treeData]) => {
        //   let data = [];
        //   Object.entries(treeData).forEach(([name, pages]) => {
        //     data.push({"pageNo": pages, "pageTitle": name});
        //   });

        //   this.treeData.push({"key": key, "values": data});
        // });
      }
    );
  }
  cursorStyle: { top: string; left: string } = { top: '0px', left: '0px' };

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    // Ensure both top and left are assigned as strings
    if(this.isEditMode){
      this.cursorStyle = {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`
      };
    }
}
}
